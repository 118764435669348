import { ChangeEvent, FC, useState } from 'react'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import imageCompression from 'browser-image-compression'

import Card from 'components/card'
import MultiImageCropper from 'components/files/FileCropDialog'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import DisplayImage from 'pages/dashboard/components/DisplayImage'
import InputFileUpload from 'pages/dashboard/components/InputFileUpload'
import { deleteFile, getPresignedUrl, uploadFile } from 'services/files'
import { updateOrganization } from 'services/organization'
import { Organization } from 'services/organization/index.types'
import { validateFile } from 'utils/validation'

interface BrandingProps {
  icon?: string
  logo?: string
  coverImages?: string[]
  coverImagesMobile?: string[]
  onUpdate?: (org?: Organization) => void
}

const fileUrl = process.env.REACT_APP_FILE_URL

const Branding: FC<BrandingProps> = ({
  icon,
  logo,
  coverImages,
  coverImagesMobile,
  onUpdate,
}): JSX.Element => {
  const { organizationId } = useAuth()
  const [error, setError] = useState<{ type: string; message: string }>()
  const [uploadProgress, setUploadProgress] = useState({
    field: '',
    fileName: '',
    progress: 0,
  })
  const [isIconUploading, setIsIconUploading] = useState(false)
  const [isLogoUploading, setIsLogoUploading] = useState(false)
  const [isCoverImageUploading, setIsCoverImageUploading] = useState(false)
  const [isCoverMobileUploading, setIsCoverMobileUploading] = useState(false)
  const [cropFiles, setCropFiles] = useState<File[] | null>(null)
  const [showCropper, setShowCropper] = useState(false)
  const [cropType, setCropType] = useState<string | null>(null)
  const [isDeleting, setIsDeleting] = useState('')

  const handleSave = async (type: string, file: File): Promise<void> => {
    if (organizationId) {
      Amplitude.trackEvent('WEBSITE_BRANDING_UPLOADFILE_CLICKED', {
        organizationId,
      })
    }

    if (type === 'icon') setIsIconUploading(true)
    else if (type === 'logo') setIsLogoUploading(true)

    const { url: signedUrl, fileName: uuid } = await getPresignedUrl()
    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      field: type,
      fileName: file.name,
      progress: 0,
    }))

    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: 'image/webp',
    }

    const compressedFile = await imageCompression(file, options)

    const result = await uploadFile(compressedFile, signedUrl, (progress) => {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        progress: progress,
      }))
    })

    if (result.status === 'successful') {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        progress: 100,
      }))

      const data: Record<string, string> = {}

      if (type === 'icon') {
        data.logo = fileUrl + '/' + uuid
      } else if (type === 'logo') {
        data.logoWithBrandName = fileUrl + '/' + uuid
      }

      const res = organizationId
        ? await updateOrganization(organizationId, data)
        : { status: 'failed' }

      if (res?.status === 'successful') {
        onUpdate?.(res.data)
        setUploadProgress({
          field: '',
          fileName: '',
          progress: 0,
        })
      }
    }

    if (type === 'icon') setIsIconUploading(false)
    else if (type === 'logo') setIsLogoUploading(false)
  }

  const handleCoverImageSave = async (files: File[]): Promise<void> => {
    if (!files.length) return

    try {
      if (organizationId) {
        Amplitude.trackEvent('WEBSITE_BRANDING_UPLOADFILE_CLICKED', {
          organizationId,
        })
      }

      setIsCoverImageUploading(true)

      const newCoverImages: string[] = []

      for (const file of files) {
        const { url: signedUrl, fileName: uuid } = await getPresignedUrl()

        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          field: 'coverImage',
          fileName: file.name,
          progress: 0,
        }))

        const uploadResult = await uploadFile(file, signedUrl, (progress) => {
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            progress,
          }))
        })

        if (uploadResult.status === 'successful') {
          newCoverImages.push(`${fileUrl}/${uuid}`)
        } else {
          console.error(`Failed to upload file: ${file.name}`)
        }

        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          progress: 100,
        }))
      }

      if (newCoverImages.length > 0) {
        const existingCoverImages = coverImages ?? []

        const updatedCoverImages = [...newCoverImages, ...existingCoverImages]

        const updateResult = organizationId
          ? await updateOrganization(organizationId, {
              coverImages: updatedCoverImages,
            })
          : { status: 'failed' }

        if (updateResult?.status === 'successful') {
          onUpdate?.(updateResult.data)
        } else {
          console.error('Failed to update organization with new cover images.')
        }
      }
    } catch (error) {
      console.error('Error during cover image upload:', error)
    } finally {
      setIsCoverImageUploading(false)
      setUploadProgress({
        field: '',
        fileName: '',
        progress: 0,
      })
    }
  }

  const handleCoverMobileSave = async (files: File[]): Promise<void> => {
    if (!files.length) return

    try {
      if (organizationId) {
        Amplitude.trackEvent('WEBSITE_BRANDING_UPLOADFILE_CLICKED', {
          organizationId,
        })
      }

      setIsCoverMobileUploading(true)

      const newCoverImagesMobile: string[] = []

      for (const file of files) {
        const { url: signedUrl, fileName: uuid } = await getPresignedUrl()

        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          field: 'coverMobile',
          fileName: file.name,
          progress: 0,
        }))

        const uploadResult = await uploadFile(file, signedUrl, (progress) => {
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            progress,
          }))
        })

        if (uploadResult.status === 'successful') {
          newCoverImagesMobile.push(`${fileUrl}/${uuid}`)
        } else {
          console.error(`Failed to upload file: ${file.name}`)
        }

        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          progress: 100,
        }))
      }

      if (newCoverImagesMobile.length > 0) {
        const existingCoverImages = coverImagesMobile ?? []

        const newImages = [...newCoverImagesMobile, ...existingCoverImages]

        const updateResult = organizationId
          ? await updateOrganization(organizationId, {
              coverImagesMobile: newImages,
            })
          : { status: 'failed' }

        if (updateResult?.status === 'successful') {
          onUpdate?.(updateResult.data)
        } else {
          console.error('Failed to update organization with new cover images.')
        }
      }
    } catch (error) {
      console.error('Error during cover image upload:', error)
    } finally {
      setIsCoverMobileUploading(false)
      setUploadProgress({
        field: '',
        fileName: '',
        progress: 0,
      })
    }
  }

  const handleFileChange = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files) return
    const type = e.target.name

    if (type === 'coverImage' || type === 'coverMobile') {
      const files = e.target.files

      for (const file of files) {
        const validate = await validateFile(file)
        if (!validate.valid) {
          setError({ type, message: validate.error })
          return
        }
      }

      setCropFiles(Array.from(files))
      setCropType(type)
      setShowCropper(true)
      setError(undefined)
    } else {
      const file = e.target.files[0]
      const type = e.target.name
      const validate = await validateFile(file)

      if (!validate.valid)
        return setError({ type: type, message: validate.error })
      handleSave(type, file)
    }
  }

  const handleCropComplete = (croppedFiles: File[]): void => {
    if (cropType === 'coverImage') handleCoverImageSave(croppedFiles)
    else if (cropType === 'coverMobile') handleCoverMobileSave(croppedFiles)
    setCropFiles(null)
    setShowCropper(false)
    setCropType(null)
  }

  const handleCropCancel = (): void => {
    setShowCropper(false)
    setCropFiles(null)
  }

  const onDelete = async (url: string, type: string): Promise<void> => {
    setIsDeleting(type)

    const fileName = url.split('/')[3]
    const result = await deleteFile(fileName)
    const data: Record<string, null | string[]> = {}
    if (organizationId) {
      if (type === 'icon') {
        data.logo = null
        Amplitude.trackEvent('WEBSITE_BRANDING_ICON_DELETE_CLICKED', {
          organizationId,
        })
      } else if (type === 'logo') {
        data.logoWithBrandName = null
        Amplitude.trackEvent('WEBSITE_BRANDING_LOGO_DELETE_CLICKED', {
          organizationId,
        })
      } else {
        const existingCoverImages = coverImages ?? []
        data.coverImages = existingCoverImages.filter(
          (imageUrl) => imageUrl !== url
        )
        Amplitude.trackEvent('WEBSITE_BRANDING_COVERIMG_DELETE_CLICKED', {
          organizationId,
        })
      }
    }

    if (result.status === 'successful') {
      const res = organizationId
        ? await updateOrganization(organizationId, data)
        : { status: 'failed' }

      if (res && res.status === 'successful') onUpdate?.(res.data)
    }
    setIsDeleting('')
  }

  const onDeleteCoverMobile = async (url: string): Promise<void> => {
    setIsDeleting('coverMobile')
    const fileName = url.split('/')[3]
    const result = await deleteFile(fileName)
    if (organizationId) {
      Amplitude.trackEvent('WEBSITE_BRANDING_COVERIMGMOBILE_DELETE_CLICKED', {
        organizationId,
      })
    }
    const existingCoverImages = coverImagesMobile ?? []
    const newImages = existingCoverImages.filter((imageUrl) => imageUrl !== url)

    if (result.status === 'successful') {
      const res = organizationId
        ? await updateOrganization(organizationId, {
            coverImagesMobile: newImages,
          })
        : { status: 'failed' }

      if (res && res.status === 'successful') onUpdate?.(res.data)
    }
    setIsDeleting('')
  }

  return (
    <Box flex={1}>
      <h2 className="text-lg font-semibold ml-5">Branding</h2>
      <Card>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <div className="flex items-center">
              <Label htmlFor="icon" text="Icon" />
              <Tooltip
                title="Upload a small square image that represents your website "
                arrow
                placement="top"
              >
                <IconButton
                  sx={{
                    fontSize: '0.875rem',
                    padding: '0.25rem',
                    mb: '0.25rem',
                  }}
                >
                  <InfoOutlinedIcon fontSize="inherit" color="info" />
                </IconButton>
              </Tooltip>
            </div>

            {icon && (
              <>
                <DisplayImage
                  imageUrl={icon}
                  onDelete={() => onDelete(icon, 'icon')}
                  height={40}
                  width={40}
                  isDeleting={isDeleting === 'icon'}
                />
              </>
            )}
            {!icon && (
              <>
                <InputFileUpload
                  id="icon"
                  name="icon"
                  onChange={handleFileChange}
                  buttonText={isIconUploading ? 'Uploading' : 'Upload File'}
                />
                {uploadProgress.field === 'icon' && (
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      color: 'accents.secondaryAccent',
                    }}
                  >
                    {uploadProgress.fileName} {uploadProgress.progress}
                  </Typography>
                )}
                {error && error.type === 'icon' && (
                  <Typography fontSize={'0.75rem'} color={'error'}>
                    *{error.message}
                  </Typography>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="flex items-center">
              <Label text="Logo" />
              <Tooltip title="Upload your brand's logo." arrow placement="top">
                <IconButton
                  sx={{
                    fontSize: '0.875rem',
                    padding: '0.25rem',
                    mb: '0.25rem',
                  }}
                >
                  <InfoOutlinedIcon fontSize="inherit" color="info" />
                </IconButton>
              </Tooltip>
            </div>

            {logo && (
              <>
                <DisplayImage
                  imageUrl={logo}
                  onDelete={() => onDelete(logo, 'logo')}
                  isDeleting={isDeleting === 'logo'}
                  width={80}
                  height={40}
                />
              </>
            )}
            {!logo && (
              <>
                <InputFileUpload
                  name="logo"
                  onChange={handleFileChange}
                  buttonText={isLogoUploading ? 'Uploading' : 'Upload File'}
                />
                {uploadProgress.field === 'logo' && (
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      color: 'accents.secondaryAccent',
                    }}
                  >
                    {uploadProgress.fileName} {uploadProgress.progress}
                  </Typography>
                )}
                {error && error.type === 'logo' && (
                  <Typography fontSize={'0.75rem'} color={'error'}>
                    *{error.message}
                  </Typography>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="flex items-center">
              <Label text="Cover Images" />
              <Tooltip
                title="Upload a large cover image or banner."
                arrow
                placement="top"
              >
                <IconButton
                  sx={{
                    fontSize: '0.875rem',
                    padding: '0.25rem',
                    mb: '0.25rem',
                  }}
                >
                  <InfoOutlinedIcon fontSize="inherit" color="info" />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <InputFileUpload
                name="coverImage"
                onChange={handleFileChange}
                buttonText={
                  isCoverImageUploading
                    ? 'Uploading'
                    : coverImages && coverImages.length > 0
                      ? 'Upload More'
                      : 'Upload File'
                }
                multiple
              />
              {uploadProgress.field === 'coverImage' && (
                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    color: 'accents.secondaryAccent',
                  }}
                >
                  {uploadProgress.fileName} {uploadProgress.progress}
                </Typography>
              )}
              {error && error.type === 'coverImage' && (
                <Typography fontSize={'0.75rem'} color={'error'}>
                  *{error.message}
                </Typography>
              )}
            </div>
            {coverImages && coverImages.length > 0 && (
              <div className=" mt-2">
                <Grid container spacing={1}>
                  {coverImages.map((url, index) => (
                    <Grid item key={index} xs={3}>
                      <DisplayImage
                        imageUrl={url}
                        onDelete={() => onDelete(url, 'cover')}
                        height={200}
                        isDeleting={isDeleting === 'cover'}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="flex items-center">
              <Label text="Cover Images Mobile" />
              <Tooltip
                title="Upload mobile cover image or banner."
                arrow
                placement="top"
              >
                <IconButton
                  sx={{
                    fontSize: '0.875rem',
                    padding: '0.25rem',
                    mb: '0.25rem',
                  }}
                >
                  <InfoOutlinedIcon fontSize="inherit" color="info" />
                </IconButton>
              </Tooltip>
            </div>
            <InputFileUpload
              name="coverMobile"
              onChange={handleFileChange}
              buttonText={
                isCoverMobileUploading
                  ? 'Uploading'
                  : coverImagesMobile && coverImagesMobile.length > 0
                    ? 'Upload More'
                    : 'Upload File'
              }
              multiple
            />
            {uploadProgress.field === 'coverMobile' && (
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'accents.secondaryAccent',
                }}
              >
                {uploadProgress.fileName} {uploadProgress.progress}
              </Typography>
            )}
            {error && error.type === 'coverMobile' && (
              <Typography fontSize={'0.75rem'} color={'error'}>
                *{error.message}
              </Typography>
            )}
            {coverImagesMobile && coverImagesMobile.length > 0 && (
              <>
                <div className=" mt-2">
                  <Grid container spacing={1}>
                    {coverImagesMobile.map((url, index) => (
                      <Grid item key={index} xs={2} sm={3} md={2}>
                        <DisplayImage
                          imageUrl={url}
                          onDelete={() => onDeleteCoverMobile(url)}
                          isDeleting={isDeleting === 'coverMobile'}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Card>
      {showCropper && cropFiles && (
        <Dialog open={showCropper} maxWidth="md" fullWidth>
          <MultiImageCropper
            files={cropFiles}
            onCropComplete={handleCropComplete}
            onClose={handleCropCancel}
            aspect={cropType === 'coverImage' ? 16 / 9 : undefined}
          />
        </Dialog>
      )}
    </Box>
  )
}

export default Branding
