import { FC, useEffect, useState } from 'react'

import {
  Box,
  Button,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import DOMPurify from 'dompurify'
import { useNavigate } from 'react-router-dom'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useSnackbar } from 'hooks/useSnackbar'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import TextCollapse from 'pages/dashboard/components/TextCollapse'
import { getCategories, updateProduct } from 'services/products'
import { getTags } from 'services/tags'
import { Category, Product, ProductOption } from 'types/product'
import { Tag } from 'types/tag'
import { formatPrice } from 'utils/helper'

interface ProductReviewProps {
  product: Product
  variantOptions: ProductOption[]
  onEditStep: (step: number) => void
  onPrevStep: (step?: number) => void
}

const ProductReview: FC<ProductReviewProps> = ({
  product,
  variantOptions,
  onEditStep,
  onPrevStep,
}) => {
  const [categoryNames, setCategoryNames] = useState<string | null>(null)
  const [tagNames, setTagNames] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    const category = product.categoryIds
    const fetchCategories = async (orgId: number): Promise<void> => {
      const result = await getCategories(orgId)
      if (result?.status === 'successful' && category && category.length > 0) {
        const data = result.data.filter((el: Category) =>
          category.includes(el.id)
        )
        const categories = data.map((el: Category) => el.name).join(', ')
        setCategoryNames(categories)
      } else if (category?.length === 0) {
        setCategoryNames(null)
      }
    }
    fetchCategories(product.organizationId)
  }, [product])
  useEffect(() => {
    const fetchTags = async (orgId: number): Promise<void> => {
      const result = await getTags('product', orgId)
      if (result?.status === 'successful' && product.tagIds.length > 0) {
        const data = result.data.filter((el: Tag) =>
          product.tagIds.includes(el.id)
        )
        const namesList = data.map((el: Tag) => el.name)
        setTagNames(namesList)
      }
    }
    if (product.organizationId) fetchTags(product.organizationId)
  }, [product.organizationId, product.tagIds])

  const handleNextStep = async (): Promise<void> => {
    setIsSubmitting(true)
    const res = await updateProduct({
      productId: product.id,
      dataUpdate: {},
    })
    if (res.status === 'successful') {
      showSnackbar('Product added successfully', 'success')
      navigate('/dashboard/products/all')
    } else {
      showSnackbar('Failed to update product status', 'error')
    }
    setIsSubmitting(false)
  }
  console.warn('product and optionType', product, variantOptions)
  return (
    <div className="mb-5">
      <Box
        className="m-5 p-5 rounded"
        sx={{ border: '1px solid', borderColor: 'accents.lightGrayAccent' }}
      >
        <Typography variant="h6" gutterBottom>
          Preview and Verify Your Product Details
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Before finalizing, take a moment to review all the details of your
          product. Ensure the information is accurate, images are correctly
          displayed, and pricing is as intended. Once you&apos;re satisfied, you
          can proceed to save and add the product. If you need to make changes,
          you can go back and edit any section.
        </Typography>
        <Button variant="outlined" size="small" sx={{ textTransform: 'none' }}>
          Preview Product
        </Button>
      </Box>
      {/* Basic Details Step 1 */}
      <div className="px-5">
        <SubTabHeader
          title="Basic Details"
          buttonText="Edit"
          onClick={() => onEditStep(1)}
        />
      </div>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Label text="Name" color="secondary" />
            <Typography sx={{ fontWeight: '700' }}>
              {product.name ?? 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text="Category" color="secondary" />
            <Typography sx={{ fontWeight: '700' }}>
              {categoryNames?.toUpperCase() ?? 'N/A'}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Label text="Description" color="secondary" />
            <TextCollapse text={product.description ?? 'N/A'} />
          </Grid>
        </Grid>
      </Card>

      <div className="px-5">
        <SubTabHeader
          title="Pricing & Stock"
          buttonText="Edit"
          onClick={() => onEditStep(1)}
        />
      </div>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Label text="Price" color="secondary" />
            <Typography sx={{ fontWeight: '700' }}>
              {formatPrice(product.price) ?? 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text="Stock" color="secondary" />
            <Typography sx={{ fontWeight: '700' }}>
              {product.stock ?? 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      </Card>

      {/* Image Details  */}
      <div className="px-5">
        {' '}
        <SubTabHeader
          title="Pictures & Video"
          buttonText="Edit"
          onClick={() => onEditStep(2)}
        />
      </div>
      <Box
        className="m-5 my-2 whitespace-nowrap overflow-x-scroll"
        sx={{ scrollbarWidth: 'none' }}
      >
        {product.media &&
          product.media.map((url, index) => (
            <div key={index} className="h-52 w-36 inline-block mr-2 rounded">
              <CardMedia
                component="img"
                image={url}
                alt={`media-${index}`}
                className="w-full h-52 object-contain"
              />
            </div>
          ))}
      </Box>
      {/* VariantOption Details  */}
      <div className="px-5">
        <SubTabHeader
          title="Variants"
          buttonText="Edit"
          onClick={() => onEditStep(3)}
        />
      </div>
      {variantOptions.length > 0 ? (
        variantOptions.map((option) => (
          <Box key={option.id} className="m-5">
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              {option.name} ({option.type})
            </Typography>
            <Grid container spacing={1} mt={2} className="px-5">
              {option.ProductOptionValue.map((optValue) => (
                <Grid container spacing={1} key={optValue.id} mb={1}>
                  <Grid item xs={12} md={4}>
                    <Label text="Option" color="secondary" />
                    <Typography fontWeight={600}>{optValue.value}</Typography>
                  </Grid>
                  {option.type.toLowerCase() === 'primary' ? (
                    <Grid item xs={12} md={4}>
                      <Label text="Selling Price" color="secondary" />
                      <Typography fontWeight={600}>
                        {formatPrice(optValue.price ?? 0)}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={4}>
                      <Label text="Additional Price" color="secondary" />
                      <Typography fontWeight={600}>
                        {formatPrice(optValue.priceAdjustment)}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <Label text="Quantity in Stock" color="secondary" />
                    <Typography fontWeight={600}>
                      {optValue.stock ?? 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))
      ) : (
        <Typography sx={{ m: 2, color: 'textSecondary' }} className="px-1">
          No variants available.
        </Typography>
      )}
      {/* Notes and Tag Detials  */}
      <div className="px-5">
        <SubTabHeader
          title="Notes & Tags"
          buttonText="Edit"
          onClick={() => onEditStep(6)}
        />
      </div>
      <Card>
        <Label text="Notes" color="secondary" />
        <Typography
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(product.notes || 'N/A'),
          }}
        />
        <br />
        <Label text="Tags" color="secondary" />
        <Typography sx={{ fontWeight: '700' }}>
          {tagNames.length > 0 ? tagNames.join(', ') : 'N/A'}
        </Typography>
      </Card>
      {/* Detail about isactive and islisted */}
      <div className="px-5">
        <SubTabHeader
          title="Manage Status"
          buttonText="Edit"
          onClick={() => onEditStep(7)}
        />
      </div>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Label text="Is Active" color="secondary" />
            <Typography>{product.isActive ? 'Yes' : 'No'}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Label text="Is Listed" color="secondary" />
            <Typography>{product.isListed ? 'Yes' : 'No'}</Typography>
          </Grid>
        </Grid>
      </Card>
      <div className="mx-5">
        <div className="flex items-center w-full">
          <div className="flex-1 basis-1/2">
            <Button
              size="small"
              variant="outlined"
              sx={{
                textTransform: 'none',
              }}
              onClick={() => onPrevStep(7)}
            >
              Prev: Manage Status
            </Button>
          </div>
          <div className="flex-1 basis-3/5">
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleNextStep}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Adding...' : 'Add Product'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProductReview
