import { useEffect, useState } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import { useAuth } from 'hooks/useAuth'
import SetupStorePrompt from 'pages/dashboard/components/SetupStorePrompt'
import { getOrganization } from 'services/organization'
import { Organization } from 'services/organization/index.types'

import About from './components/About'
import Branding from './components/Branding'
import PolicyDocuments from './components/PolicyDocs'
import Social from './components/Social'
import UITheme from './components/UITheme'

const WebsiteBuilder = (): JSX.Element => {
  const { organizationId, organization } = useAuth()
  const [orgData, setOrgData] = useState<Organization>()
  const [isLoading, setIsLoading] = useState<boolean>()

  useEffect(() => {
    async function get(): Promise<void> {
      setIsLoading(true)
      const result = organizationId
        ? await getOrganization(organizationId)
        : { status: 'failed' }

      if (result.status === 'successful' && result.data) {
        setOrgData(result.data)
      }
      setIsLoading(false)
    }
    get()
  }, [organizationId])

  const handleUpdate = (org?: Organization): void => {
    setOrgData(org)
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh" // Adjust this to fit your layout
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Helmet>
        <title>
          Website
          {organization && organization.name ? ` | ${organization.name}` : ''}
        </title>
      </Helmet>
      {!orgData && isLoading === false && <SetupStorePrompt />}
      {orgData && (
        <Box>
          <Box display={{ xs: 'block' }}>
            <Branding
              icon={orgData.logo}
              logo={orgData.logoWithBrandName}
              coverImages={orgData.coverImages}
              coverImagesMobile={orgData.coverImagesMobile}
              onUpdate={handleUpdate}
            />
            <UITheme
              mainColor={orgData.primaryColor}
              secondaryColor={orgData.secondaryColor}
              onUpdate={handleUpdate}
            />
          </Box>
          <About
            description={orgData.description}
            imageUrl={orgData.imageUrl}
          />
          <Social social={orgData.social} onUpdate={handleUpdate} />
          <PolicyDocuments
            policies={orgData.policies}
            onUpdate={handleUpdate}
          />
        </Box>
      )}
    </>
  )
}

export default WebsiteBuilder
