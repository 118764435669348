import { Box } from '@mui/material'

interface GenericChipProps<T> {
  label: string
  value: T
  color: string
  isSelected: boolean
  onClick: (value: T) => void
}

const GenericChip = <T extends string>({
  label,
  value,
  color,
  isSelected,
  onClick,
}: GenericChipProps<T>): JSX.Element => {
  return (
    <Box
      onClick={() => onClick(value)}
      sx={{
        cursor: 'pointer',
        border: `1px solid ${color}`,
        backgroundColor: isSelected ? color : 'transparent',
        color: isSelected ? '#fff' : color,
        transition: 'background-color 0.3s, color 0.3s',
        '&:hover': {
          backgroundColor: isSelected ? color : `${color}20`,
          color: isSelected ? '#fff' : color,
        },
      }}
      className="px-2 py-1 rounded-full text-xs font-bold mr-2 flex items-center justify-center "
    >
      {label}
    </Box>
  )
}

export default GenericChip
