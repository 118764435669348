import { useEffect, useState } from 'react'

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import LoadingSpinner from 'components/spinner'
import { useSnackbar } from 'hooks/useSnackbar'
import ConfirmationDialog from 'pages/dashboard/components/ConfrimationDialog'
import { deleteProduct, getProduct, updateProduct } from 'services/products'
import { Product } from 'types/product'

import DetailPreview from '../components/DetailPreview'
import EditBasicDetails from '../components/EditBasicDetails'
import FilesDetails from '../components/FilesDetails'
import ManageStatusDetail from '../components/ManageStatusDetail'
import NotesDetails from '../components/NotesDetails'
import OffersDetails from '../components/OffersDetails'
import PricingDetails from '../components/PricingDetails'
import BasicDetails from '../components/ProductDetails'
// import SeoDetails from '../components/SeoDetails'
import SizeChart from '../components/SizeChart'
import TagsDetails from '../components/TagsDetails'
import VariantsDetails from '../components/VariantsDetails'

const ProductDetailsPage = (): JSX.Element => {
  const [product, setProduct] = useState<Product>()
  const { productId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const naviagte = useNavigate()
  const [open, setOpen] = useState(false)
  const [dataUpdate, setDataUpdate] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [edit, setEdit] = useState(false)
  const openAction = Boolean(anchorEl)

  const { showSnackbar } = useSnackbar()

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  useEffect(() => {
    async function get(): Promise<void> {
      setIsLoading(true)
      const result = productId
        ? await getProduct({ id: Number(productId) })
        : { status: 'failed' }
      if (result.status === 'successful') setProduct(result.data)
      setIsLoading(false)
    }
    get()
  }, [productId])

  useEffect(() => {
    async function get(): Promise<void> {
      setIsLoading(true)
      const result = productId
        ? await getProduct({ id: Number(productId) })
        : { status: 'failed' }
      if (result.status === 'successful') {
        setProduct(result.data)
      }
      setIsLoading(false)
      setDataUpdate(false)
    }
    if (dataUpdate) get()
  }, [dataUpdate, productId])

  const handleUpdate = (data?: Product): void => {
    if (data) {
      setProduct(data)
    } else {
      setDataUpdate(true)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleActionClose = (): void => {
    setAnchorEl(null)
  }

  const handleDeleteDialog = (): void => {
    setDeleteDialog(true)
  }

  const handleDeleteProduct = async (): Promise<void> => {
    if (product) {
      const result = await deleteProduct(product.id)
      if (result.status === 'successful') {
        showSnackbar('Product Deleted.', 'success')
        naviagte(-1)
      } else if (result.status === 'failed') {
        showSnackbar(result.message ?? 'Please try again later.', 'error')
      }
    }
    setDeleteDialog(false)
    handleClose()
  }

  const handleEdit = (): void => {
    setEdit(!edit)
    handleClose()
  }

  const handleSizeChartChange = async (url?: string): Promise<void> => {
    if (!product) return
    const result = await updateProduct({
      productId: product.id,
      dataUpdate: { sizeChart: url ?? null },
    })

    if (result.status === 'successful') {
      setProduct(result.data)
      showSnackbar('Size chart updated', 'success')
    } else if (result.status === 'failed') {
      showSnackbar('Please try again later.', 'error')
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!product) return <h1>No Product found.</h1>

  const previewProductData = {
    name: product.name,
    price: product.price,
    discountedPrice: product.discountedPrice,
    description: product.description,
    options: product.ProductOption.flatMap((option) =>
      option.ProductOptionValue.map((optionValue) => ({
        id: optionValue.id,
        name: option.name,
        type: option.type,
        value: optionValue.value,
        price: optionValue.price,
        priceAdjustment: optionValue.priceAdjustment,
        discountedPrice: optionValue.discountedPrice,
      }))
    ),
  }
  const mediaFiles = product.media.map((m) => m.split('/')[3])

  const handleSave = async () => {
    if (edit) {
      const formElement = document.getElementById(
        'formik-form'
      ) as HTMLFormElement
      formElement?.requestSubmit()

      const updatedProduct = { ...product, name: product.name }

      if (updatedProduct.name !== product.name) {
        updatedProduct.name = product.name
      }
      const result = await updateProduct({
        productId: product.id,
        dataUpdate: { name: updatedProduct.name },
      })
      if (result.status === 'successful') {
        showSnackbar('Product updated successfully.', 'success')
        setProduct(result.data)
      } else {
        showSnackbar('Failed to update product.', 'error')
      }
    }
    setEdit(!edit)
  }

  return (
    <Box className="mb-5">
      <div className=" flex items-center justify-between mr-5">
        <div className="flex items-center ml-2 w-full">
          <IconButton sx={{ color: 'gray' }} onClick={() => naviagte(-1)}>
            <ArrowBackOutlinedIcon />
          </IconButton>
          <p className="text-xl w-full font-bold">
            {edit ? (
              <TextField
                value={product.name}
                onChange={(e) =>
                  setProduct({ ...product, name: e.target.value })
                }
                variant="outlined"
                size="small"
                sx={{
                  width: '100%',
                }}
              />
            ) : (
              `${product.name} (${product.productCode})`
            )}
          </p>
        </div>
        <div className="flex">
          <Button
            variant="outlined"
            size="small"
            sx={{
              marginX: 1,
              textTransform: 'none',
            }}
            onClick={handleSave}
          >
            {edit ? 'Save' : 'Edit'}
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              paddingInline: '1rem',
              textTransform: 'none',
            }}
            id="basic-button"
            aria-controls={openAction ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openAction ? 'true' : undefined}
            onClick={handleClick}
          >
            Actions
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openAction}
            onClose={handleActionClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '& .MuiMenuItem-root': {
                '&:hover': {
                  backgroundColor: 'lightgray',
                },
              },
            }}
          >
            <MenuItem onClick={handleClickOpen}>Show Product Preview</MenuItem>
            <MenuItem onClick={handleDeleteDialog}>Delete Product</MenuItem>
          </Menu>
        </div>
      </div>
      {!edit && <BasicDetails product={product} />}
      {edit && (
        <EditBasicDetails
          product={product}
          onUpdate={handleUpdate}
          onEditClose={handleEdit}
        />
      )}
      {product.ProductOption.length === 0 && (
        <PricingDetails product={product} onUpdate={handleUpdate} />
      )}

      <FilesDetails media={product.media} productId={product.id} />

      {product.ProductOption.length === 0 && (
        <OffersDetails
          price={product.price}
          discountedPrice={product.discountedPrice}
          discountPercentage={product.discountPercentage}
          offerValidity={product.offerValidity}
          productId={product.id}
          onUpdate={handleUpdate}
        />
      )}

      {product.ProductOption.length > 0 && (
        <VariantsDetails
          variants={product.ProductOption}
          productCode={product.productCode}
          onUpdate={handleUpdate}
        />
      )}
      <SizeChart
        sizeChartUrl={product.sizeChart}
        onSizeChartChange={handleSizeChartChange}
      />

      {/* <SeoDetails
        seoValues={{
          seoTitle: product.seoTitle,
          seoDescription: product.seoDescription,
          seoLink: product.seoLink,
        }}
        productId={product.id}
        onUpdate={handleUpdate}
      /> */}
      <NotesDetails productId={product.id} notesData={product.notes} />
      <TagsDetails
        productId={product.id}
        tagIds={product.tagIds}
        onUpdate={handleUpdate}
      />
      <ManageStatusDetail product={product} onUpdate={handleUpdate} />
      <div>
        <ConfirmationDialog
          open={deleteDialog}
          title="Delete Product"
          content="This action will permanently delete the product. Are you sure you want to proceed?"
          onClose={() => setDeleteDialog(false)}
          onConfirm={handleDeleteProduct}
        />
      </div>
      <div>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogTitle>Preview</DialogTitle>
          <DialogContent>
            <DetailPreview product={previewProductData} files={mediaFiles} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  )
}

export default ProductDetailsPage
