import { FC, useState } from 'react'

import EastIcon from '@mui/icons-material/East'
import { Box, Card, CardActionArea } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import ImageComponent from 'components/ImgPlaceholder'

export type Category = {
  id: number
  name: string
  createdAt: Date
  updatedAt: Date
  organizationId: number
  imageUrl: string | undefined
}

interface CategoryCardProps {
  item: Category
}

const CategoryCard: FC<CategoryCardProps> = ({ item }) => {
  const navigate = useNavigate()
  const [elevation, setElevation] = useState(1)
  const [hover, setHover] = useState(false)

  const capitalizeEachWord = (input: string): string => {
    return input
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  return (
    <Box
      sx={{
        position: 'relative',
        transition: 'transform 0.3s, box-shadow 0.3s',
        transform: hover ? 'translateY(-10px)' : 'translateY(0)',
        width: '100%',
        maxWidth: {
          xs: '180px',
          md: '250px',
          xl: '180px',
        },
        margin: 'auto',
        paddingTop: 3,
      }}
      onMouseEnter={() => {
        setElevation(12)
        setHover(true)
      }}
      onMouseLeave={() => {
        setElevation(1)
        setHover(false)
      }}
    >
      <Card
        elevation={elevation}
        sx={{
          cursor: 'pointer',
          width: '100%',
        }}
        onClick={() => navigate(`${item.id}`)}
      >
        <CardActionArea>
          <Box
            sx={{
              width: '100%',
              aspectRatio: '3 / 4',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
            className="rounded"
          >
            <ImageComponent imageUrl={item.imageUrl} altText={item.name} />
          </Box>
        </CardActionArea>
      </Card>
      <Box sx={{ padding: '8px 0' }} onClick={() => navigate(`${item.id}`)}>
        <p className="font-bold text-sm flex items-center cursor-pointer">
          {capitalizeEachWord(item.name)}
          <EastIcon className="ml-2 transform transition-transform duration-200 hover:scale-110" />
        </p>
      </Box>
    </Box>
  )
}

export default CategoryCard
