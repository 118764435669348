import { useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CircleIcon from '@mui/icons-material/Circle'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { updateProduct } from 'services/products'
import { ProductOption } from 'services/products/index.types'
import { Product } from 'types/product'

import ProductDetails from '../components/Details'
import ProductFiles from '../components/Files'
import NotesAndTags from '../components/NotesAndTags'
import ProductReview from '../components/ProductReview'
import ProductStatus from '../components/Status'
import VariantOptions from '../components/VariantOptions'
import OptionPriceAndStock from '../components/VariantPriceStock'
import ProductVariants from '../components/Variants'

interface AddProductPageProps {
  productId?: number
}
export interface Variants {
  name: string
  type: string
  price: string
  quantity: string
  costPerUnit: string
  discountedPrice: string
  discountPercentage: string
  offerValidTill: [Date | null, Date | null]
}

export interface ProductDetailFormValues {
  name: string
  category: number[]
  description: string
  price: string
  quantity: string
  costPerUnit: string
  variants: Variants[]
  discountedPrice: string
  discountPercentage: string
  offerValidTill: [Date | null, Date | null]
  seoTitle: string
  seoDescription: string
  seoLink: string
  notes: string
  tagIds: number[]
  isActive: boolean
  isListed: boolean
}

const stepsTitle = [
  'Add Basic Details',
  'Upload Product Images',
  'Variants',
  'Define Variant Options',
  'Assign Variant Price & Stock',
  'Add Notes & Tags',
  'Manage Status',
  'Review',
]

const AddProductPage: React.FC<AddProductPageProps> = () => {
  const navigate = useNavigate()
  const [variantOptions, setVariantOptions] = useState<ProductOption[]>([])
  const [productId, setProductId] = useState<number | null>(null)
  const [productCode, setProductCode] = useState<string | null>(null)
  const [product, setProduct] = useState<Product | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [step, setStep] = useState(1)

  const handleBackNavigation = (): void => {
    navigate(-1)
  }

  const handleVariantOptions = (option: ProductOption): void => {
    setVariantOptions((prev) => [...prev, option])
  }

  const handleOptionsUpdate = (options: ProductOption[]): void => {
    setVariantOptions(options)
  }

  const handleRemoveVariantOption = (optionId: number): void => {
    setVariantOptions((prev) => prev.filter((option) => option.id !== optionId))
  }

  const handleCreateProduct = (id: number, code: string): void => {
    setProductId(id)
    setProductCode(code)
    handleNextStep()
  }

  const handleNoVariantsStep = (): void => {
    setStep(6)
  }

  const handleNextStep = (): void => {
    setStep(step + 1)
  }

  const handleProductUpdate = (product: Product): void => {
    setProduct(product)
  }

  const handlePrevStep = (stp?: number): void => {
    if (stp) {
      setStep(stp)
    } else setStep((prev) => prev - 1)
  }

  const handleCancel = async (): Promise<void> => {
    setIsSubmitting(true)
    const result = productId
      ? await updateProduct({
          productId,
          dataUpdate: {
            status: 'DRAFT',
          },
        })
      : { status: 'failed' }

    if (result.status === 'successful') {
      navigate('/dashboard/products/all', { replace: true })
    }
    setIsSubmitting(false)
  }

  const handleEditStep = (stepToEdit: number): void => {
    setStep(stepToEdit)
  }

  return (
    <>
      <div className="flex items-center mx-5">
        <CircleIcon sx={{ fontSize: 11, color: 'text.secondary', mr: 0.5 }} />
        <Typography variant="body1" fontSize={12} color={'text.secondary'}>
          Step {step}/{stepsTitle.length}
        </Typography>
      </div>
      <Box
        display="flex"
        alignItems={'center'}
        marginBottom={2}
        justifyContent={'space-between'}
        className="mr-5 ml-2"
      >
        <div className="flex items-center">
          <IconButton
            sx={{ color: 'text.primary' }}
            onClick={handleBackNavigation}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="body1">
            <Typography
              component="span"
              color="text.primary"
              fontSize={20}
              fontWeight={700}
            >
              Add New Product :{' '}
            </Typography>
            <Typography
              component="span"
              color="primary"
              fontSize={20}
              fontWeight={700}
            >
              {stepsTitle[step - 1]}
            </Typography>
          </Typography>
        </div>
        {step !== 1 && (
          <Button
            variant="outlined"
            size="small"
            sx={{
              textTransform: 'none',
              color: 'black',
              borderColor: '#d1d5db',
              '&:hover': {
                borderColor: '#6b7280',
              },
            }}
            disabled={isSubmitting}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        )}
      </Box>
      <Box className="mb-5">
        {step === 1 && (
          <ProductDetails
            onProductCreate={handleCreateProduct}
            onCreate={handleProductUpdate}
            product={product}
            onNextStep={handleNextStep}
          />
        )}
        {step === 2 && productId && (
          <ProductFiles
            onNextStep={handleNextStep}
            productId={productId}
            onPrevStep={handlePrevStep}
          />
        )}
        {step === 3 && (
          <ProductVariants
            handleNoVariantsStep={handleNoVariantsStep}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
          />
        )}
        {step === 4 && productCode && (
          <VariantOptions
            productCode={productCode}
            onNextStep={handleNextStep}
            handleVariantOptions={handleVariantOptions}
            handleNoVariantsStep={handleNoVariantsStep}
            onRemoveVariantOption={handleRemoveVariantOption}
            variantOptions={variantOptions}
            onPrevStep={handlePrevStep}
          />
        )}
        {step === 5 && productCode && (
          <OptionPriceAndStock
            productCode={productCode}
            variantOptions={variantOptions}
            handleNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
            product={product}
            onUpdateOptions={handleOptionsUpdate}
          />
        )}

        {step === 6 && productId && (
          <NotesAndTags
            onNextStep={handleNextStep}
            productId={productId}
            productNotes={product?.notes}
            productTags={product?.tagIds}
            onProductUpdate={handleProductUpdate}
            onPrevStep={handlePrevStep}
            variantOptionsLength={variantOptions.length}
          />
        )}
        {step === 7 && productId && (
          <ProductStatus
            productId={productId}
            onPrevStep={handlePrevStep}
            onNextStep={handleNextStep}
            onProductUpdate={handleProductUpdate}
          />
        )}
        {step === 8 && product && variantOptions && (
          <ProductReview
            product={product}
            variantOptions={variantOptions}
            onEditStep={handleEditStep}
            onPrevStep={handlePrevStep}
          />
        )}
      </Box>
    </>
  )
}

export default AddProductPage
