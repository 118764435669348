import { useState } from 'react'

import { Dialog } from '@mui/material'
import imageCompression from 'browser-image-compression'

import { getPresignedUrl, uploadFile } from 'services/files'
import { validateProductImage } from 'utils/validation'

import MultiImageCropper from './FileCropDialog'
import FileUploadList from './FileUploadList'
import FileUploadZone from './FileUploadZone'

interface FileUploadProps {
  onClose?: () => void
  onSuccess: (file: string) => void
}

const FileUpload = ({ onClose, onSuccess }: FileUploadProps): JSX.Element => {
  const [files, setFiles] = useState<File[]>([])
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>(
    {}
  )
  const [error, setError] = useState({ valid: true, message: '' })
  const [showCropper, setShowCropper] = useState<boolean>(false)
  const [croppedFiles, setCroppedFiles] = useState<File[]>([])

  const addFiles = async (accfiles: File[]): Promise<void> => {
    setError({ valid: true, message: '' })
    // Removing files that already added
    const filterFiles = accfiles.filter(
      (file) => !files.some((existingFile) => existingFile.name === file.name)
    )

    for (let i = filterFiles.length - 1; i >= 0; i--) {
      const file = filterFiles[i]
      const validationResult = await validateProductImage(file)

      if (!validationResult.valid) {
        setError(validationResult)
        filterFiles.splice(i, 1)
      }
    }
    setCroppedFiles(filterFiles)
    if (filterFiles.length > 0) setShowCropper(true)
  }

  const deleteFile = (file: File): void => {
    setFiles((prev) => prev.filter((f) => f !== file))
  }

  const handleCropComplete = (croppedFiles: File[]): void => {
    setCroppedFiles([])
    setFiles((prev) => [...prev, ...croppedFiles])
    setShowCropper(false)
    fileSubmitHandler(croppedFiles)
  }

  const fileSubmitHandler = async (files: File[]): Promise<void> => {
    if (files.length === 0) return
    try {
      for (const file of files) {
        if (uploadProgress[file.name] === 100) continue

        //Get presigned url
        const { url: signedUrl, fileName: uuid } = await getPresignedUrl()
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 0,
        }))

        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1600,
          useWebWorker: true,
          fileType: 'image/jpeg',
        }

        const compressedFile = await imageCompression(file, options)
        // upload file to presigned url
        const result = await uploadFile(
          compressedFile,
          signedUrl,
          (progress) => {
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: progress,
            }))
          }
        )

        if (result.status === 'successful') {
          onSuccess(uuid)
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: 100,
          }))
        }
      }
      setFiles([])
      setUploadProgress({})
      onClose?.()
    } catch (error) {
      console.error('Error uploading files:', error)
    } finally {
      setError({ valid: true, message: '' })
    }
  }

  const handleCropCancel = (): void => {
    setShowCropper(false)
    setCroppedFiles([])
  }

  return (
    <div>
      <FileUploadZone onAddFiles={addFiles} />
      {!error.valid && (
        <p className="text-xs text-red-500 mb-2 mt-1">{error.message}</p>
      )}
      {files.length > 0 && (
        <>
          <FileUploadList
            files={files}
            onDelete={deleteFile}
            progress={uploadProgress}
          />
        </>
      )}

      {showCropper && (
        <Dialog open={showCropper} maxWidth="md" fullWidth>
          <MultiImageCropper
            files={croppedFiles}
            onCropComplete={handleCropComplete}
            onClose={handleCropCancel}
          />
        </Dialog>
      )}
    </div>
  )
}

export default FileUpload
