import { FC, SyntheticEvent, useState } from 'react'

import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material'

import Label from 'components/forms/Label'
import { AvailableCourierCompany } from 'services/delivery/index.types'

import { companiesUrl } from './CourierServiceDialog'

interface CompanyAccordionProps {
  company: AvailableCourierCompany
}

const CompanyAccordion: FC<CompanyAccordionProps> = ({ company }) => {
  const [expanded, setExpanded] = useState(false)

  const handleAccordionExpandChange = (
    event: SyntheticEvent,
    isExpanded: boolean
  ): void => {
    setExpanded(isExpanded)
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionExpandChange}
      elevation={0}
      sx={{
        border: '1px solid',
        borderColor: 'rgba(0,0,0,0.12)',
        borderRadius: '4px',
        marginY: '12px',
        '&::before ': {
          content: 'none',
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel2-content"
        id="panel2-header"
        sx={{
          '.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded': {
            marginBottom: 0,
          },
        }}
      >
        <div className="w-full">
          <div className=" flex items-center justify-between">
            <Typography fontWeight={600} mb={1}>
              <a
                href={
                  companiesUrl.find(
                    (el) => el.id === company.courier_company_id
                  )?.url
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {company.courier_name}
              </a>
            </Typography>
            <ArrowDropUpOutlinedIcon
              sx={{
                transition: 'transform 0.3s',
                transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Label text="Estimated Delivery" color="secondary" />
              <Typography>{`${company.estimated_delivery_days} days (${company.etd})`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Label text="Freight Charges" color="secondary" />
              <Typography>₹{company.freight_charge}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Label text="Max Weight Supported" color="secondary" />
              <Typography>
                {company.mode
                  ? company.air_max_weight
                  : company.surface_max_weight}{' '}
                kg
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Label text="Pickup Performance" color="secondary" />
              <Typography>{company.pickup_performance}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Label text="Delivery Performance" color="secondary" />
              <Typography>{company.delivery_performance}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Label text="Tracking" color="secondary" />
              <Typography>{company.realtime_tracking}</Typography>
            </Grid>
          </Grid>
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Label text="COD Charges" color="secondary" />
            <Typography>₹{company.cod_charges}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Label text="RTO Charges" color="secondary" />
            <Typography>₹{company.rto_charges}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Label text="Call Before Delivery" color="secondary" />
            <Typography>{company.call_before_delivery}</Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default CompanyAccordion
