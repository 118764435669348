import React, { useState } from 'react'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  List,
  ListItem,
  Divider,
  ListItemText,
  Typography,
} from '@mui/material'
import Select, { SingleValue } from 'react-select'

import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'

import MultiEmailField from './MultiEmailField'

interface User {
  firstName: string | null
  lastName: string | null
  email: string
}

interface OrganizationUser {
  userId: number
  organizationId: number
  role: 'ADMIN' | 'MEMBER' | 'OWNER'
  createdAt: string
  updatedAt: string
  invitedBy: number | null
  acceptedAt: string | null
  declinedAt: string | null
  isActive: boolean
  user: User
}

type AcceptingUser = {
  userId: number
  fullName: string
  role: string
  email: string
}

type PendingUser = {
  userId: number
  role: string
  email: string
}

interface ManageAccessDialogProps {
  open: boolean
  onClose: () => void
  storeUsers?: OrganizationUser[]
  onInvite: (emails: string[]) => void
  onDeleteUser: (email: string) => void
  onRoleChange: (userId: number, newRole: string) => Promise<boolean>
}

interface RoleOption {
  value: string
  label: string
}

const roleOptions: RoleOption[] = [
  { value: 'ADMIN', label: 'Administrator' },
  { value: 'MEMBER', label: 'Member' },
  { value: 'OWNER', label: 'OWNER' },
]

const ManageAccessDialog: React.FC<ManageAccessDialogProps> = ({
  open,
  onClose,
  storeUsers = [],
  onInvite,
  onDeleteUser,
  onRoleChange,
}) => {
  const [inviteEmails, setInviteEmails] = useState<
    { label: string; value: string }[]
  >([])
  const [invitedUsers, setInvitedUsers] = useState<
    { email: string; role: string }[]
  >([])

  const [roleSelections, setRoleSelections] = useState<Record<number, string>>(
    {}
  )
  const { organizationId } = useAuth()

  const handleInvite = () => {
    if (organizationId) {
      Amplitude.trackEvent('STORE_MANAGEACCESS_INVITE_CLICKED', {
        orgId: organizationId,
      })
    }
    if (inviteEmails.length > 0) {
      const newInvites = inviteEmails.map((email) => ({
        email: email.value,
        role: 'Member',
      }))
      setInvitedUsers([...invitedUsers, ...newInvites])
      onInvite(inviteEmails.map((email) => email.value))
      setInviteEmails([])
    }
  }

  const handleRoleChange = async (userId: number, newRole: string) => {
    try {
      const response = await onRoleChange(userId, newRole)
      if (response) {
        setRoleSelections((prevSelections) => ({
          ...prevSelections,
          [userId]: newRole,
        }))
      }
    } catch (error) {
      console.error('Failed to update role:', error)
    }
  }

  const acceptingUsers: AcceptingUser[] = storeUsers
    .filter(
      (user) =>
        (user.acceptedAt !== null &&
          user.declinedAt !== null &&
          user.isActive === false) ||
        user.role === 'OWNER'
    )
    .map((user) => ({
      userId: user.userId,
      fullName:
        `${user.user?.firstName || ''} ${user.user?.lastName || ''}`.trim(),
      role: user.role,
      email: user.user ? user.user.email : 'Unknown Email',
    }))

  const pendingUsers: PendingUser[] = storeUsers
    .filter(
      (user) =>
        user.acceptedAt === null &&
        user.declinedAt === null &&
        !user.isActive &&
        user.role !== 'OWNER'
    )
    .map((user) => ({
      userId: user.userId,
      role: user.role,
      email: user.user ? user.user.email : 'Unknown Email',
    }))

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Manage Access</DialogTitle>
      <DialogContent>
        <Box className="flex flex-col sm:flex-row mb-4" sx={{ width: '100%' }}>
          <Box sx={{ flex: 1 }}>
            <MultiEmailField value={inviteEmails} onChange={setInviteEmails} />
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleInvite}
            className="w-32 h-10"
            sx={{
              marginLeft: { sm: '1rem' },
              marginTop: { xs: '0.5rem', sm: '0.2rem' },
            }}
          >
            Invite
          </Button>
        </Box>

        <Box className="rounded border" sx={{ overflowX: 'auto' }}>
          <List>
            {acceptingUsers.length > 0 &&
              acceptingUsers.map((user) => (
                <React.Fragment key={user.userId}>
                  <ListItem
                    sx={{
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: 'flex-start',
                    }}
                  >
                    <ListItemText
                      primary={user.fullName}
                      secondary={user.email}
                      sx={{ flex: '1 1 auto' }}
                    />
                    <Box className="rounded border p-2  md:text-center  md:w-[144px] md:mr-10">
                      <Typography variant="body2">{user.role}</Typography>
                    </Box>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
          </List>

          <List>
            {pendingUsers.length > 0 &&
              pendingUsers.map((user, index) => (
                <React.Fragment key={user.userId}>
                  <ListItem sx={{ flexWrap: 'wrap' }}>
                    <ListItemText
                      primary={user.email}
                      secondary="Pending Invite"
                      sx={{ flex: '1 1 auto' }}
                    />
                    <Select
                      value={
                        roleOptions.find(
                          (option) =>
                            option.value ===
                            (roleSelections[user.userId] || user.role)
                        ) ||
                        roleOptions.find((option) => option.value === 'Member')
                      }
                      onChange={(selectedOption: SingleValue<RoleOption>) =>
                        handleRoleChange(
                          user.userId,
                          selectedOption?.value || 'MEMBER'
                        )
                      }
                      options={roleOptions}
                      styles={{
                        control: (base) => ({
                          ...base,
                          fontSize: '0.875rem',
                          width: '144px',
                        }),
                      }}
                      isSearchable={false}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: theme.colors.neutral20,
                        },
                      })}
                    />
                    <IconButton
                      onClick={() => onDeleteUser(user.email)}
                      sx={{
                        color: 'black',
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </ListItem>
                  {index < pendingUsers.length - 1 && <Divider />}
                </React.Fragment>
              ))}
          </List>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ManageAccessDialog
