import { useEffect, useState } from 'react'

import LoadingSpinner from 'components/spinner'
import { useAuth } from 'hooks/useAuth'
import EmptyTabContent from 'pages/dashboard/components/EmptyTabContent'
import SetupStorePrompt from 'pages/dashboard/components/SetupStorePrompt'
import { getAllStock } from 'services/stock'

import StockList from '../components/StockList'

// This Page will show customers List
const StockPage = (): JSX.Element => {
  const [tableActive, setTableActive] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { organizationId, organization } = useAuth()

  useEffect(() => {
    async function fetchStock(): Promise<void> {
      if (organizationId) {
        setIsLoading(true)
        const result = await getAllStock({ orgId: organizationId })
        if (result && result.data.length > 0) {
          setTableActive(true)
        } else {
          setTableActive(false)
        }
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    }
    fetchStock()
  }, [organizationId])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (organization == null) {
    return <SetupStorePrompt />
  }

  return (
    <div>
      <div className="mb-5">
        {!tableActive && (
          <EmptyTabContent
            title="Stock"
            description="Manage your Stock, see their Stock history and group
                Stock into segments."
            //importButtonText="Import Stock"
          />
        )}

        {tableActive && organizationId && (
          <>
            <StockList orgId={organizationId} />
          </>
        )}
      </div>
    </div>
  )
}

export default StockPage
