import { FC, useState } from 'react'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material'

import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateProductOption } from 'services/products'
import { Product, ProductOption } from 'types/product'
import { formatPrice } from 'utils/helper'

interface VariantsTableProps {
  productCode: string
  optionData: ProductOption
  onUpdate: (data?: Product) => void
}

export interface RowValue {
  id?: number
  optionId: number
  value: string
  priceAdjustment?: string
  stock: number | null
  media: string[]
}

const VariantsTable: FC<VariantsTableProps> = ({
  optionData,
  productCode,
  onUpdate,
}) => {
  const [rows, setRows] = useState<RowValue[]>(
    optionData.ProductOptionValue.map((val) => ({
      id: val.id,
      optionId: val.optionId,
      value: val.value,
      priceAdjustment: val.priceAdjustment
        ? (val.priceAdjustment / 100).toString()
        : '',
      stock: val.stock,
      media: val.media,
    }))
  )
  const [edit, setEdit] = useState(false)
  const [optionName, setOptionName] = useState(optionData.name)
  const [optionError, setOptionError] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()

  const [error, setError] = useState({
    idx: -1,
    fieldName: '',
    message: '',
  })

  const handleNumberFieldChange = (
    value: string,
    index: number,
    fieldName: string
  ): void => {
    const data = rows.map((v, idx) => {
      if (idx === index) {
        return { ...v, [fieldName]: Number(value) }
      }
      return v
    })
    setRows(data)
  }

  const handleFieldChange = (
    value: string,
    index: number,
    fieldName: string
  ): void => {
    const data = rows.map((v, idx) => {
      if (idx === index) {
        return { ...v, [fieldName]: value }
      }
      return v
    })
    setRows(data)
  }

  const handleBlur = (
    value: string,
    index: number,
    fieldName: string
  ): void => {
    const data = rows.at(index)

    if (data && value === '') {
      setError({
        idx: index,
        fieldName: fieldName + index,
        message: fieldName + ' is required.',
      })
    } else {
      setError({
        idx: -1,
        fieldName: '',
        message: '',
      })
    }
  }

  const handleRowAdd = (): void => {
    setRows((prev) => [
      ...prev,
      {
        optionId: optionData.id,
        priceAdjustment: undefined,
        stock: null,
        media: [],
        value: '',
      },
    ])
  }

  const handleRowDelete = (index: number): void => {
    const filterRows = rows.filter((obj, idx) => idx !== index)
    setRows(filterRows)
  }

  const handleSave = async (): Promise<void> => {
    if (optionName.trim() === '') {
      setOptionError('Option name is required.')
      return
    }
    rows.forEach((row, index) => {
      if (row.value === '') {
        setError({
          idx: index,
          fieldName: `value${index}`,
          message: '*Option value is required',
        })
        return
      }
    })

    const data = {
      name: optionName,
      values: rows.map((val) => ({
        valueId: val.id,
        value: val.value,
        priceAdjustment: val.priceAdjustment
          ? Number(val.priceAdjustment) * 100
          : undefined,
        stock: val.stock ? Number(val.stock) : undefined,
      })),
    }

    setIsSubmitting(true)

    const result = organizationId
      ? await updateProductOption(
          organizationId,
          productCode,
          optionData.id,
          data
        )
      : { status: 'failed' }

    if (result.status === 'successful' && result.data) {
      onUpdate()
      showSnackbar('Option updated', 'success')
    } else if (result.status === 'failed') {
      showSnackbar(result.message ?? 'Please try again later', 'error')
    }

    setIsSubmitting(false)
  }

  return (
    <>
      <div className="mb-5">
        {!edit ? (
          <SubTabHeader
            title={optionData.name}
            buttonText={'Edit'}
            onClick={() => setEdit(true)}
          />
        ) : (
          <Box width={{ xs: '80%', md: '40%' }}>
            <CustomTextField
              name={`optionName`}
              value={optionName}
              onChange={(e) => setOptionName(e.target.value)}
              error={Boolean(optionError !== null)}
              helperText={optionError}
            />
          </Box>
        )}
      </div>
      {!edit &&
        optionData.ProductOptionValue.map((optValue) => (
          <Grid container spacing={1} key={optValue.id} mb={1}>
            <Grid item xs={12} md={4}>
              <Label text="Option" color="secondary" />
              <Typography fontWeight={600}>{optValue.value}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Label text="Additional Price" color="secondary" />
              <Typography fontWeight={600}>
                {formatPrice(optValue.priceAdjustment)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Label text="Quantity in Stock" color="secondary" />
              <Typography fontWeight={600}>
                {optValue.stock ?? 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        ))}

      {edit && (
        <div>
          {rows.map((variant, index) => (
            <Grid container key={index} spacing={2}>
              <Grid item xs={6} md={4}>
                <Label text="Option*" />
                <CustomTextField
                  name={`value${index}`}
                  value={variant.value}
                  onChange={(e) =>
                    handleFieldChange(e.target.value, index, 'value')
                  }
                  onBlur={(e) => handleBlur(e.target.value, index, 'value')}
                  error={Boolean(error.fieldName === `value${index}`)}
                  helperText={
                    error.fieldName === `value${index}` && error.message
                  }
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Label text="Price Adjustment" />
                <CustomTextField
                  type="number"
                  min={0}
                  step={0.01}
                  name={`price` + index}
                  value={variant.priceAdjustment}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    handleNumberFieldChange(
                      e.target.value,
                      index,
                      'priceAdjustment'
                    )
                  }
                  onBlur={(e) =>
                    handleBlur(e.target.value, index, 'priceAdjustment')
                  }
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Label text="Quantity" />
                <CustomTextField
                  name={`quantity${index}`}
                  min={0}
                  step={1}
                  type="number"
                  value={variant.stock ? variant.stock.toString() : ''}
                  onChange={(e) =>
                    handleNumberFieldChange(e.target.value, index, 'stock')
                  }
                  onBlur={(e) => handleBlur(e.target.value, index, 'stock')}
                />
              </Grid>
              <Grid item alignSelf={'center'} xs={1}>
                <IconButton>
                  <DeleteOutlineOutlinedIcon
                    onClick={() => handleRowDelete(index)}
                  />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </div>
      )}
      {edit && (
        <Button sx={{ fontSize: '0.8rem', mt: 1 }} onClick={handleRowAdd}>
          + Add more option
        </Button>
      )}
      {edit && (
        <div className="mt-4">
          <Button
            variant="outlined"
            size="small"
            sx={{ textTransform: 'none', mr: 2 }}
            onClick={() => setEdit(false)}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ textTransform: 'none' }}
            onClick={handleSave}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </div>
      )}
    </>
  )
}

export default VariantsTable
