import { FC, useState } from 'react'

import { Box, Button, MenuItem, TextField, Typography } from '@mui/material'
import { Formik, ErrorMessage, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import Label from 'components/forms/Label'
import QuillEditor from 'components/quill'
import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import { createOrganization } from 'services/organization'
import { getRestrictedCountriesList, getStatesOfCountry } from 'utils/countries'

import CustomTextField from './CustomTextField'
import PhoneNumberInput from './PhoneNumberInput'

interface StoreCreateFormStepProps {
  handleNext: () => void
  description: string
}
interface StoreCreateFormValues {
  name: string
  email: string
  phoneNumber: string
  orgDescription?: string
  countryCode: string
  address: {
    addressLine1: string
    addressLine2?: string
    city: string
    state: string
    country: string
    pinCode: string
  }
}

const phoneRegExp = /^((\+)?(\d{1,3}))?(\d{10})$/

const storeCreateFormSchema = Yup.object().shape({
  name: Yup.string().required('*Store name is required'),
  email: Yup.string()
    .email('*Enter a valid email')
    .required('*Email is required'),
  orgDescription: Yup.string().optional(),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, '*Enter a valid phone number')
    .required('*Phone number is required'),
  address: Yup.object()
    .shape({
      addressLine1: Yup.string().required(
        '*Apartment, Suite, etc. is required'
      ),
      addressLine2: Yup.string().required('*Address is required'),
      city: Yup.string().required('*City is required'),
      state: Yup.string().required('*State is required'),
      country: Yup.string().required('*Country is required'),
      pinCode: Yup.string().required('*Pin Code is required'),
    })
    .optional(),
})

const StoreCreateFormStep: FC<StoreCreateFormStepProps> = ({
  handleNext,
  description,
}) => {
  const countriesList = getRestrictedCountriesList()
  const { changeOrganization } = useAuth()
  const [selectedCountry, setSelectedCountry] = useState<string>('')
  const initialValues: StoreCreateFormValues = {
    name: '',
    email: '',
    phoneNumber: '',
    orgDescription: '',
    countryCode: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      pinCode: '',
    },
  }

  const restrictTo = countriesList.find(
    (country) => country.name === selectedCountry
  )?.isoCode
  const statesList = getStatesOfCountry(restrictTo)

  const handleOnChange =
    (setFieldValue: FormikHelpers<StoreCreateFormValues>['setFieldValue']) =>
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
      const { name, value } = event.target
      setFieldValue(name, value)
    }

  const handleStoreSetup = async (
    values: StoreCreateFormValues
  ): Promise<void> => {
    Amplitude.trackEvent(`DASHBOARD_HOME_BUTTON_CLICKED`, {
      buttonName: 'SET UP STORE',
    })
    const { name, email, phoneNumber, countryCode, orgDescription, address } =
      values

    const result = await createOrganization({
      name,
      email,
      phoneNumber,
      countryCode,
      address,
      description: orgDescription,
    })

    if (result?.status === 'successful' && result.data) {
      changeOrganization(result.data.id)
    }
    handleNext()
  }

  return (
    <Box
      className="p-4 shadow rounded"
      component="div"
      sx={{ backgroundColor: '#FAFAFA' }}
    >
      <Typography variant="subtitle1" gutterBottom>
        {description}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={storeCreateFormSchema}
        onSubmit={handleStoreSetup}
      >
        {({
          setFieldValue,
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => (
          <Form className="w-full">
            <div>
              <Label text="Name" htmlFor="name" color="secondary" />
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                inputProps={{ style: { backgroundColor: 'white' } }}
                size="small"
                name="name"
                value={values.name}
                onChange={handleOnChange(setFieldValue)}
                placeholder="Your store name"
                error={touched.name && Boolean(errors.name)}
                helperText={<ErrorMessage name="name" />}
              />
            </div>
            <div className="grid grid-cols-1 gap-4  md:grid-cols-2">
              <div>
                <Label text="Email" htmlFor="email" color="secondary" />
                <TextField
                  size="small"
                  type="text"
                  variant="outlined"
                  fullWidth
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  name="email"
                  value={values.email}
                  placeholder="support@yourstorename.haulistic.io"
                  onChange={handleOnChange(setFieldValue)}
                  error={touched.email && Boolean(errors.email)}
                  helperText={<ErrorMessage name="email" />}
                />
              </div>
              <div>
                <Label
                  text="Phone number"
                  htmlFor="phoneNumber"
                  color="secondary"
                />
                <PhoneNumberInput
                  countriesList={countriesList}
                  countryCodeName="countryCode"
                  phoneNumberName="phoneNumber"
                  value={{
                    countryCode: values.countryCode,
                    phoneNumber: values.phoneNumber,
                  }}
                  error={{
                    countryCode:
                      touched.countryCode && errors.countryCode
                        ? errors.countryCode
                        : '',
                    phoneNumber:
                      touched.phoneNumber && errors.phoneNumber
                        ? errors.phoneNumber
                        : '',
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={{
                    phoneNumber: touched.countryCode ? errors.phoneNumber : '',
                  }}
                />
              </div>
            </div>
            <div>
              <Label
                text="Description (Optional)"
                htmlFor="orgDescription"
                color="secondary"
              />
              <QuillEditor
                value={values.orgDescription ?? ''}
                onChange={(val) => setFieldValue('orgDescription', val)}
                limit={1000}
              />
            </div>
            <div>
              <Label
                text="Country"
                htmlFor="address.country"
                color="secondary"
              />
              <TextField
                SelectProps={{ style: { backgroundColor: 'white' } }}
                onChange={(e) => {
                  handleChange(e)
                  setSelectedCountry(e.target.value)
                }}
                onBlur={handleBlur}
                name={'address.country'}
                value={values.address.country}
                sx={{ marginTop: 0 }}
                fullWidth
                id="country"
                variant="outlined"
                margin="normal"
                size="small"
                error={
                  touched.address?.country && Boolean(errors.address?.country)
                }
                helperText={touched.address?.country && errors.address?.country}
                select
              >
                {countriesList.map((val) => (
                  <MenuItem
                    key={val.name}
                    value={val.name}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'lightgray',
                      },
                    }}
                  >
                    {`${val.flagIcon} ${val.name}`}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <Label
                text="Apartment, Suite, etc."
                htmlFor="address.addressLine1"
                color="secondary"
              />
              <CustomTextField
                name="address.addressLine1"
                value={values.address.addressLine1}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.address?.addressLine1 &&
                  Boolean(errors.address?.addressLine1)
                }
                helperText={<ErrorMessage name="address.addressLine1" />}
              />
            </div>
            <div>
              <Label
                text="Address"
                htmlFor="address.addressLine2"
                color="secondary"
              />
              <CustomTextField
                name="address.addressLine2"
                value={values.address.addressLine2}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.address?.addressLine2 &&
                  Boolean(errors.address?.addressLine2)
                }
                helperText={<ErrorMessage name="address.addressLine2" />}
              />
            </div>
            <div className="grid grid-cols-1 gap-4  md:grid-cols-3">
              <div>
                <Label text="City" htmlFor="address.city" color="secondary" />
                <CustomTextField
                  name="address.city"
                  value={values.address.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.address?.city && Boolean(errors.address?.city)}
                  helperText={<ErrorMessage name="address.city" />}
                />
              </div>
              <div>
                <Label text="State" htmlFor="address.state" color="secondary" />
                <TextField
                  SelectProps={{ style: { backgroundColor: 'white' } }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={'address.state'}
                  value={values.address.state}
                  sx={{ marginTop: 0 }}
                  fullWidth
                  id="state"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  error={
                    touched.address?.state && Boolean(errors.address?.state)
                  }
                  helperText={touched.address?.state && errors.address?.state}
                  select
                >
                  {statesList.length === 0 && (
                    <MenuItem disabled>Choose country to select state</MenuItem>
                  )}
                  {statesList.map((val) => (
                    <MenuItem
                      key={val.name}
                      value={val.name}
                      sx={{
                        fontSize: '0.75rem',
                        padding: '2px 8px',
                        '&:hover': {
                          backgroundColor: 'lightgray',
                        },
                      }}
                    >
                      {val.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div>
                <Label
                  text="Pin Code"
                  htmlFor="address.pinCode"
                  color="secondary"
                />
                <CustomTextField
                  name="address.pinCode"
                  value={values.address.pinCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.address?.pinCode && Boolean(errors.address?.pinCode)
                  }
                  helperText={<ErrorMessage name="address.pinCode" />}
                />
              </div>
            </div>
            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
              Set up store
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default StoreCreateFormStep
