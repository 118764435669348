import { FC, useState } from 'react'

import {
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import { MultiValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import { createProductOption, deleteProductOption } from 'services/products'
import { ProductOption } from 'services/products/index.types'

type OptionType = {
  label: string
  value: string
  id: number
}

interface Option {
  name: string
  values: string[]
  isPrimary: boolean
  optionId: string | number
}

interface OptionInputProps {
  option: Option
  onAddOption: (option: ProductOption) => void
  index: number
  onDelete: (index: number) => void
  productCode: string
  onRemoveVariantOption: (optionId: number) => void
  onRowOptionAdd: (option: Option, index: number) => void
}

const OptionInput: FC<OptionInputProps> = ({
  option,
  onAddOption,
  index,
  onDelete,
  productCode,
  onRemoveVariantOption,
  onRowOptionAdd,
}) => {
  const theme = useTheme()
  const [tagOptions, setTagOptions] = useState<MultiValue<OptionType>>([])
  const [value, setValue] = useState<MultiValue<OptionType>>()
  const [optionName, setOptionName] = useState<string>('')
  const [isPrimary, setIsPrimary] = useState<boolean>(false)
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleChange = (newValue: MultiValue<OptionType>): void => {
    setValue(newValue)
  }

  const handleGenerateStandardSizeOptions = (): void => {
    const sizeOptions = [
      { label: 'XXS', value: 'XXS', id: 1 },
      { label: 'XS', value: 'XS', id: 2 },
      { label: 'S', value: 'S', id: 3 },
      { label: 'M', value: 'M', id: 4 },
      { label: 'L', value: 'L', id: 5 },
      { label: 'XL', value: 'XL', id: 6 },
      { label: 'XXL', value: 'XXL', id: 7 },
      { label: '3XL', value: '3XL', id: 8 },
      { label: '4XL', value: '4XL', id: 9 },
    ]
    setTagOptions(sizeOptions)
    setValue(sizeOptions)
  }

  const handlePrimaryOptionChange = (): void => {
    setIsPrimary(!isPrimary)
  }

  const handleOptionDone = async (): Promise<void> => {
    if (optionName.trim() === '') {
      setError('*Please provide option name')
      return
    }
    if (value?.length === 0) {
      setError('*Please provide option values')
      return
    }
    const data = {
      name: optionName,
      values: value ? value.map((vl) => vl.label) : [],
      type: isPrimary ? 'PRIMARY' : 'SECONDARY',
    }

    const res = organizationId
      ? await createProductOption(productCode, organizationId, data)
      : { status: 'failed', message: 'Please try again later.' }

    if (res.status === 'successful' && res.data) {
      onAddOption(res.data)
      setError(null)

      onRowOptionAdd(
        {
          name: optionName,
          values: value ? value.map((vl) => vl.label) : [],
          isPrimary: isPrimary,
          optionId: res.data.id.toString(),
        },
        index
      )
    } else if (res.status === 'failed') {
      showSnackbar(res.message || 'Please try again later.', 'error')
    }
  }

  const handleRemoveOption = (): void => {
    onDelete(index)
  }

  const handleOptionDelete = async (
    optionId: number | undefined
  ): Promise<void> => {
    if (!optionId) return
    setIsDeleting(true)
    const res = organizationId
      ? await deleteProductOption(organizationId, productCode, optionId)
      : { status: 'failed', message: 'Please try again later.' }

    if (res.status === 'successful') {
      showSnackbar(
        res.message || 'Product option deleted successfully',
        'success'
      )
      onDelete(index)
      onRemoveVariantOption(optionId)
    } else if (res.status === 'failed') {
      showSnackbar(res.message || 'Please try again later.', 'error')
    }
    setIsDeleting(false)
  }

  return (
    <div>
      {error && <p className="text-sm text-red-500 my-2">{error}</p>}
      {option.optionId !== '' && (
        <div>
          <div className="mb-2">
            <Label text="Option Name" color="secondary" />
            <Typography variant="body1" fontSize={16} fontWeight={600}>
              {option.name}
            </Typography>
          </div>
          <div className="mb-2">
            <Label text="Option Value" color="secondary" />
            <Typography variant="body1" fontSize={16} fontWeight={600}>
              {option.values.join(', ')}
            </Typography>
          </div>
          <div className="mb-2">
            <Label text="Primary" color="secondary" />
            <Typography variant="body1" fontSize={16} fontWeight={600}>
              {option.isPrimary ? 'Yes' : 'No'}
            </Typography>
          </div>
          <div className="my-4 flex justify-end">
            <Button
              variant="text"
              size="small"
              sx={{
                textTransform: 'none',
                color: 'black',
                backgroundColor: 'white',
                border: '1px solid #e0e0e0',
                mr: 2,
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                  color: 'black',
                },
                padding: '1px 16px',
                fontSize: '12px',
              }}
              disabled={isDeleting}
              onClick={() => handleOptionDelete(Number(option.optionId))}
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
          </div>
        </div>
      )}
      {option.optionId === '' && (
        <>
          <div>
            <Label text="Option Name" htmlFor="option.name" />
            <CreatableSelect
              name="option.name"
              value={
                optionName ? { label: optionName, value: optionName } : null
              }
              onChange={(newValue) => setOptionName(newValue?.value || '')}
              options={[
                { label: 'Size', value: 'Size' },
                { label: 'Color', value: 'Color' },
              ]}
              placeholder="Enter or select option name"
              styles={{
                control: (base, state) => ({
                  ...base,
                  minHeight: '40px',
                  backgroundColor: theme.palette.background.paper,
                  borderWidth: state.isFocused ? '2px' : '1px',
                  borderColor: state.isFocused
                    ? theme.palette.primary.main
                    : 'rgba(0, 0, 0, 0.23)',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: state.isFocused
                      ? theme.palette.primary.main
                      : '#333333',
                  },
                }),
              }}
            />
          </div>
          <div className=" mt-3">
            <Label text="Option Value" htmlFor="option.values" />
            <CreatableSelect
              name="option.values"
              isMulti
              isClearable
              options={tagOptions}
              value={value}
              onChange={handleChange}
              placeholder=""
              onCreateOption={(inputValue) => {
                const newOption = {
                  label: inputValue,
                  value: inputValue,
                  id: Math.random(),
                }
                setTagOptions((prev) => [...prev, newOption])
                setValue((prev) => [...(prev || []), newOption])
              }}
              styles={{
                control: (base, state) => ({
                  ...base,
                  minHeight: '40px',
                  backgroundColor: theme.palette.background.paper,
                  borderWidth: state.isFocused ? '2px' : '1px',
                  borderColor: state.isFocused
                    ? theme.palette.primary.main
                    : 'rgba(0, 0, 0, 0.23)',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: state.isFocused
                      ? theme.palette.primary.main
                      : '#333333',
                  },
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 2,
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? theme.palette.action.selected
                    : state.isFocused
                      ? theme.palette.grey[400]
                      : theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  '&:hover': {
                    backgroundColor: theme.palette.grey[400],
                  },
                }),
              }}
            />
          </div>
          {optionName.toLowerCase() === 'size' && (
            <ButtonBase
              sx={{
                textTransform: 'none',
                textDecoration: 'underline',
                color: 'text.primary',
                fontSize: '14px',
                marginTop: '10px',
              }}
              onClick={handleGenerateStandardSizeOptions}
            >
              Generate Standard Size Options
            </ButtonBase>
          )}
          <div className="my-2">
            <FormControlLabel
              control={<Checkbox checked={isPrimary} />}
              label="Mark as primary variant"
              onChange={handlePrimaryOptionChange}
            />
          </div>
          {isPrimary && (
            <Paper
              elevation={0}
              className=" p-4 border border-gray-200 mb-4 mt-2"
            >
              <p className="text-xs underline font-bold mb-3">Note</p>
              <p className="text-xs">
                Marking this as your primary variant lets you assign a fixed
                (absolute) price for each option within it. All other variants
                can only adjust prices (for example, by adding or subtracting a
                certain amount) from this primary variant’s base price.
                <br />
                <br />
                <strong>Example:</strong>
                <br />
              </p>
              <ul className="list-disc pl-4 text-xs">
                <li>
                  Suppose you mark &quot;Full Set&quot; as your primary variant
                  and set its price at $100.
                </li>
                <li>
                  For a &quot;Size&quot; variant, you could then add $10 for
                  larger sizes (e.g., 3XL) or subtract $5 for smaller sizes
                  (e.g., XS).
                </li>
                <li>
                  You&apos;re not entering an entirely new price each time—just
                  adjustments relative to the primary variant&apos;s $100 base.
                </li>
              </ul>
            </Paper>
          )}
          <div className="my-4 flex justify-end">
            <Button
              variant="text"
              size="small"
              sx={{
                textTransform: 'none',
                color: 'black',
                backgroundColor: 'white',
                border: '1px solid #e0e0e0',
                mr: 2,
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                  color: 'black',
                },
                padding: '1px 16px',
                fontSize: '12px',
              }}
              onClick={handleRemoveOption}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              size="small"
              disableElevation
              sx={{
                textTransform: 'none',
                padding: '1px 16px',
                fontSize: '12px',
              }}
              onClick={handleOptionDone}
            >
              Done
            </Button>
          </div>
        </>
      )}
      <hr className="my-4" />
    </div>
  )
}

export default OptionInput
