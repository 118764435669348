import React, { useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'

import Carousel from 'components/carousel/Carousel'
import TextCollapse from 'pages/dashboard/components/TextCollapse'
import { formatPrice } from 'utils/helper'

interface PreviewOption {
  id: number
  name: string
  type: string
  value: string
  price: number | null
  discountedPrice: number | null
  priceAdjustment: number
}
interface PreviewProduct {
  name: string
  price?: number
  discountedPrice?: number
  options: PreviewOption[]
  description?: string
}
interface PreviewProps {
  files?: string[]
  product: PreviewProduct
}

const Preview: React.FC<PreviewProps> = ({ files, product }): JSX.Element => {
  const [variantSelect, setVariantSelect] = useState<PreviewOption | null>(null)
  const [priceAdjustment, setPriceAdjustment] = useState(0)
  const { name, price, discountedPrice, options, description } = product
  const [selectedSecondaryOptionIds, setSelectedSecondaryOptionIds] = useState<
    number[]
  >([])
  const [selectedColorVariantId, setSelectedColorVariantId] = useState<
    number | null
  >(null)
  const [selectedSizeVariantId, setSelectedSizeVariantId] = useState<
    number | null
  >(null)

  const primaryVariants = options.filter(
    (v) =>
      v.type.toLowerCase() === 'primary' &&
      v.price !== null &&
      v.name.toLowerCase() !== 'color' &&
      v.name.toLowerCase() !== 'size'
  )

  const colorVariants = options.filter((v) => v.name.toLowerCase() === 'color')
  const sizeVariants = options.filter((v) => v.name.toLowerCase() === 'size')

  const secondaryVariants = options.filter(
    (v) =>
      v.type.toLowerCase() === 'secondary' &&
      v.name.toLowerCase() !== 'color' &&
      v.name.toLowerCase() !== 'size'
  )

  useEffect(() => {
    if (options.length > 0) {
      options.forEach((option) => {
        if (option.type.toLowerCase() === 'primary') {
          setVariantSelect(option)
          if (option.name.toLowerCase() === 'color') {
            setSelectedColorVariantId(option.id)
          } else if (option.name.toLowerCase() === 'size') {
            setSelectedSizeVariantId(option.id)
          }
          return
        }
      })
    }
  }, [options])

  const handleSecondaryVariantClick = (variant: PreviewOption): void => {
    setPriceAdjustment(variant.priceAdjustment)
    setSelectedSecondaryOptionIds([variant.id])
  }

  const getAdjustedPrice = (basePrice: number): number => {
    return basePrice + priceAdjustment
  }

  const handleColorVariantClick = (variant: PreviewOption): void => {
    if (variant.type.toLowerCase() === 'secondary') {
      if (selectedColorVariantId) {
        setPriceAdjustment(priceAdjustment - variant.priceAdjustment)
        setSelectedColorVariantId(null)
      } else {
        setPriceAdjustment(variant.priceAdjustment)
        setSelectedColorVariantId(variant.id)
      }
    } else {
      setVariantSelect(variant)
      setSelectedColorVariantId(variant.id)
    }
  }

  const handleSizeVariantClick = (variant: PreviewOption): void => {
    if (variant.type.toLowerCase() === 'secondary') {
      if (selectedSizeVariantId) {
        setPriceAdjustment(priceAdjustment - variant.priceAdjustment)
        setSelectedSizeVariantId(null)
      } else {
        setPriceAdjustment(variant.priceAdjustment)
        setSelectedSizeVariantId(variant.id)
      }
    } else {
      setVariantSelect(variant)
      setSelectedSizeVariantId(variant.id)
    }
  }

  return (
    <Box sx={{ flex: 1 }}>
      <h2 className=" text-lg font-bold mb-2">{name}</h2>
      {files && files.length > 0 && <Carousel files={files} />}
      {options.length === 0 && price && (
        <div>
          {discountedPrice && discountedPrice > 0 ? (
            <>
              <span className=" text-lg font-semibold mr-2">
                {formatPrice(discountedPrice)}
              </span>
              <span className=" mr-2">
                <del>{formatPrice(price)}</del>
              </span>
            </>
          ) : (
            <span className=" text-lg font-semibold">{formatPrice(price)}</span>
          )}
        </div>
      )}
      {options.length > 0 && variantSelect && (
        <>
          {variantSelect.price && (
            <div className="mb-2">
              {variantSelect.discountedPrice &&
              variantSelect.discountedPrice > 0 ? (
                <>
                  <span className=" text-lg font-semibold mr-2">
                    {formatPrice(
                      getAdjustedPrice(variantSelect.discountedPrice)
                    )}
                  </span>
                  <span className=" mr-2">
                    <del>
                      {formatPrice(getAdjustedPrice(variantSelect.price))}
                    </del>
                  </span>
                </>
              ) : (
                <span className=" text-lg font-semibold">
                  {formatPrice(getAdjustedPrice(variantSelect.price))}
                </span>
              )}
            </div>
          )}
          {primaryVariants.length > 0 && (
            <div>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Options
              </Typography>
              {primaryVariants.map((variant) => (
                <button
                  type="button"
                  key={variant.id}
                  className={`bg-gray-200 text-sm rounded-xl py-1 px-3 hover:bg-gray-300 mr-2 active: ${variantSelect.id === variant.id ? 'bg-gray-400' : ''}`}
                  onClick={() => setVariantSelect(variant)}
                >
                  {variant.value}
                </button>
              ))}
            </div>
          )}
          {colorVariants.length > 0 && (
            <div className="mt-2">
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Color
              </Typography>
              {colorVariants.map((variant) => (
                <button
                  type="button"
                  key={variant.id}
                  className={`bg-gray-200 text-sm rounded-xl py-1 px-3 hover:bg-gray-300 mr-2 active: ${selectedColorVariantId === variant.id ? 'bg-gray-400' : ''}`}
                  onClick={() => handleColorVariantClick(variant)}
                >
                  {variant.value}
                </button>
              ))}
            </div>
          )}
          {sizeVariants.length > 0 && (
            <div className="mt-2">
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Size
              </Typography>
              {sizeVariants.map((variant) => (
                <button
                  type="button"
                  key={variant.id}
                  className={`bg-gray-200 text-sm rounded-xl py-1 px-3 hover:bg-gray-300 mr-2 active: ${selectedSizeVariantId === variant.id ? 'bg-gray-400' : ''}`}
                  onClick={() => handleSizeVariantClick(variant)}
                >
                  {variant.value}
                </button>
              ))}
            </div>
          )}

          {secondaryVariants.length > 0 && (
            <div className="mt-2">
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                }}
              >
                Add-ons
              </Typography>
              {secondaryVariants.map((variant) => (
                <button
                  type="button"
                  key={variant.id}
                  className={`bg-gray-200 text-sm rounded-xl py-1 px-3 hover:bg-gray-300 mr-2 active: ${selectedSecondaryOptionIds.includes(variant.id) ? 'bg-gray-400' : ''}`}
                  onClick={() => handleSecondaryVariantClick(variant)}
                >
                  {variant.value}
                </button>
              ))}
            </div>
          )}
        </>
      )}

      {description && (
        <div className="mt-2">
          <TextCollapse text={description} />
        </div>
      )}
    </Box>
  )
}

export default Preview
