import { FC, useState } from 'react'

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Chip,
  Paper,
} from '@mui/material'

import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import EditButton from 'pages/dashboard/components/EditButton'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateProductOption } from 'services/products'
import { Product, ProductOption } from 'types/product'
import { formatPrice } from 'utils/helper'

interface PrimaryTableProps {
  option: ProductOption
  productCode: string
  onUpdate: (data?: Product) => void
}

export interface RowValue {
  id?: number
  optionId: number
  value: string
  price: number | null
  stock: number | null
  media: string[]
}

const PrimaryTable: FC<PrimaryTableProps> = ({
  option,
  onUpdate,
  productCode,
}) => {
  const [rows, setRows] = useState<RowValue[]>(
    option.ProductOptionValue.map((val) => ({
      id: val.id,
      optionId: val.optionId,
      value: val.value,
      price: val.price ? val.price / 100 : null,
      stock: val.stock,
      media: val.media,
    }))
  )
  const [edit, setEdit] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [error, setError] = useState({
    idx: -1,
    fieldName: '',
    message: '',
  })

  const handleNumberFieldChange = (
    value: string,
    index: number,
    fieldName: string
  ): void => {
    const data = rows.map((v, idx) => {
      if (idx === index) {
        return { ...v, [fieldName]: Number(value) }
      }
      return v
    })
    setRows(data)
  }

  const handleFieldChange = (
    value: string,
    index: number,
    fieldName: string
  ): void => {
    const data = rows.map((v, idx) => {
      if (idx === index) {
        return { ...v, [fieldName]: value }
      }
      return v
    })
    setRows(data)
  }

  const handleBlur = (
    value: string,
    index: number,
    fieldName: string
  ): void => {
    const data = rows.at(index)

    if (data && value === '') {
      setError({
        idx: index,
        fieldName: fieldName + index,
        message: fieldName + ' is required.',
      })
    } else {
      setError({
        idx: -1,
        fieldName: '',
        message: '',
      })
    }
  }

  const handleRowAdd = (): void => {
    setRows((prev) => [
      ...prev,
      {
        optionId: option.id,
        price: null,
        stock: null,
        media: [],
        value: '',
      },
    ])
  }

  const handleRowDelete = (index: number): void => {
    const filterRows = rows.filter((obj, idx) => idx !== index)

    setRows(filterRows)
  }

  const handleSave = async () => {
    rows.forEach((row, index) => {
      if (row.value === '') {
        setError({
          idx: index,
          fieldName: `value${index}`,
          message: '*Option value is required',
        })
        return
      }
    })

    const data = {
      values: rows.map((val) => ({
        valueId: val.id,
        value: val.value,
        price: val.price ? Number(val.price) * 100 : undefined,
        stock: val.stock ? Number(val.stock) : undefined,
      })),
    }

    setIsSubmitting(true)

    const result = organizationId
      ? await updateProductOption(organizationId, productCode, option.id, data)
      : { status: 'failed' }

    if (result.status === 'successful' && result.data) {
      onUpdate()
      showSnackbar('Option updated', 'success')
    } else if (result.status === 'failed') {
      showSnackbar(result.message ?? 'Please try again later', 'error')
    }

    setIsSubmitting(false)
  }

  return (
    <Paper className="border border-gray-300 rounded m-5" elevation={0}>
      <div className=" border-b border-gray-300 flex items-center justify-between px-4 py-2">
        <div className=" flex items-center">
          <SubTabHeader title="Set Options" />
          <Chip
            label="Primary Variant Option"
            sx={{
              color: '#15803d',
              backgroundColor: '#bbf7d0',
              fontSize: '12px',
              ml: 2,
            }}
            size="small"
          />
        </div>
        {!edit && (
          <EditButton buttonText="Edit" onClick={() => setEdit(true)} />
        )}
      </div>
      <div className=" py-2 px-4">
        {!edit &&
          option.ProductOptionValue.map((optValue) => (
            <Grid container spacing={1} key={optValue.id} mb={1}>
              <Grid item xs={12} md={4}>
                <Label text="Option" color="secondary" />
                <Typography fontWeight={600}>{optValue.value}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Label text="Selling Price" color="secondary" />
                <Typography fontWeight={600}>
                  {formatPrice(optValue.price ?? 0)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Label text="Quantity in Stock" color="secondary" />
                <Typography fontWeight={600}>
                  {optValue.stock ?? 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          ))}
        {edit &&
          rows.map((variant, index) => (
            <Grid container key={index} spacing={2}>
              <Grid item xs={6} md={4}>
                <Label text="Option Name*" />
                <CustomTextField
                  type="text"
                  name={`value${index}`}
                  value={variant.value}
                  onChange={(e) =>
                    handleFieldChange(e.target.value, index, 'value')
                  }
                  onBlur={(e) => handleBlur(e.target.value, index, 'value')}
                  error={Boolean(error.fieldName === `value${index}`)}
                  helperText={
                    error.fieldName === `value${index}` && error.message
                  }
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Label text="Selling Price" />
                <CustomTextField
                  type="number"
                  min={0}
                  step={0.01}
                  name={`price` + index}
                  value={variant.price ? variant.price.toString() : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    handleNumberFieldChange(e.target.value, index, 'price')
                  }
                  onBlur={(e) => handleBlur(e.target.value, index, 'price')}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Label text="Quantity" />
                <CustomTextField
                  name={`quantity${index}`}
                  min={0}
                  step={1}
                  type="number"
                  value={variant.stock ? variant.stock.toString() : ''}
                  onChange={(e) =>
                    handleNumberFieldChange(e.target.value, index, 'stock')
                  }
                  onBlur={(e) => handleBlur(e.target.value, index, 'stock')}
                />
              </Grid>
              {index > 0 && (
                <Grid item alignSelf={'center'} xs={1}>
                  <IconButton>
                    <DeleteOutlineOutlinedIcon
                      onClick={() => handleRowDelete(index)}
                    />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ))}
        {edit && (
          <Button sx={{ fontSize: '0.8rem', mt: 1 }} onClick={handleRowAdd}>
            + Add more option
          </Button>
        )}
        {edit && (
          <div className="mt-4">
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: 'none', mr: 2 }}
              onClick={() => setEdit(false)}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleSave}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
          </div>
        )}
      </div>
    </Paper>
  )
}

export default PrimaryTable
