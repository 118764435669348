import { ChangeEvent, FC, useEffect, useState } from 'react'

import {
  Box,
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'
import { Product } from 'types/product'
import { convertRupeeToPaisa, formatPrice } from 'utils/helper'

interface PricingDetailsProps {
  product: Product
  onUpdate: (data?: Product) => void
}

const PricingDetails: FC<PricingDetailsProps> = ({ product, onUpdate }) => {
  const [values, setValues] = useState({ price: '', quantity: '', cost: '' })
  const [error, setError] = useState({ price: '', quantity: '' })
  const [edit, setEdit] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { organizationId } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [addVariants, setAddVariants] = useState(false)

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setValues({
      price: (product.price / 100).toString(),
      quantity: product.stock.toString(),
      cost:
        typeof product.cost === 'number' ? (product.cost / 100).toString() : '',
    })
  }, [product])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'price') {
      setValues((prev) => ({ ...prev, price: e.target.value }))
    } else if (e.target.name === 'quantity') {
      setValues((prev) => ({ ...prev, quantity: e.target.value }))
    } else {
      setValues((prev) => ({ ...prev, cost: e.target.value }))
    }
  }

  const handleSave = async (): Promise<void> => {
    if (values.price === '' || values.quantity === '') {
      setError({
        price: values.price === '' ? '*Price is required.' : '',
        quantity: values.quantity === '' ? '*Quantity is required.' : '',
      })
      return
    }

    const newPricePaisa = convertRupeeToPaisa(values.price)
    const newCostPaisa = convertRupeeToPaisa(values.cost || '0')

    // Check if no changes have been made
    if (
      newPricePaisa === product.price &&
      +values.quantity === product.stock &&
      newCostPaisa === product.cost
    ) {
      setEdit(false)
      return
    }

    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_PRICING_SAVE_CLICKED', {
        productId: product.id,
        orgId: organizationId,
      })
    }

    const updateStock =
      +values.quantity !== product.stock || newCostPaisa !== product.cost

    const data = {
      price: newPricePaisa,
      quantity: updateStock ? +values.quantity : undefined,
      cost: updateStock ? newCostPaisa : undefined,
    }

    setIsSubmitting(true)
    const res = await updateProduct({
      productId: product.id,
      dataUpdate: data,
    })

    if (res.status === 'successful') {
      showSnackbar('Product price updated.', 'success')
      onUpdate(res.data)
    }
    setIsSubmitting(false)
    setEdit(!edit)
  }

  const handleCancel = (): void => {
    setValues({
      price: (product.price / 100).toString(),
      quantity: product.stock.toString(),
      cost:
        typeof product.cost === 'number' ? (product.cost / 100).toString() : '',
    })
    setEdit(false)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleEdit = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_PRICING_EDIT_CLICKED', {
        productId: product.id,
        orgId: organizationId,
      })
    }
    setEdit(true)
    handleClose()
  }

  const handleAddMoreVariants = (): void => {
    setAddVariants(true)
    handleClose()
    setEdit(true)
  }

  return (
    <div className="mb-5">
      <div className="mx-5">
        {product.ProductOption.length === 0 && (
          <>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography sx={{ fontWeight: '700' }}>
                Pricing & Stock
              </Typography>
              <Button
                disableElevation
                sx={{
                  p: '1px',
                  backgroundColor: 'accents.darkGrayAccent',
                  color: 'text.primary',
                  fontSize: '0.8rem',
                  fontWeight: 700,
                  textTransform: 'none',
                  ':hover': {
                    backgroundColor: '#bdbdbd',
                  },
                }}
                onClick={handleClick}
              >
                Edit
              </Button>
            </Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{
                '& .MuiMenuItem-root': {
                  '&:hover': {
                    backgroundColor: 'lightgray',
                  },
                },
              }}
            >
              <MenuItem onClick={handleEdit} dense>
                Edit pricing/stock
              </MenuItem>
              <MenuItem onClick={handleAddMoreVariants} dense>
                Add more variants
              </MenuItem>
            </Menu>{' '}
          </>
        )}

        {product.ProductOption.length > 0 && (
          <SubTabHeader
            title="Pricing & Stock"
            buttonText={edit ? undefined : 'Edit'}
            onClick={handleEdit}
          />
        )}
      </div>
      <Card>
        {product.ProductOption.length === 0 && !addVariants && (
          <>
            <Box display={'flex'} columnGap={2} rowGap={1}>
              <Box flex={1}>
                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    color: 'text.secondary',
                    fontWeight: '600',
                  }}
                >
                  Selling Price
                </Typography>
                {edit ? (
                  <CustomTextField
                    name="price"
                    type="number"
                    min={0}
                    value={values.price}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                    error={Boolean(error.price)}
                    helperText={error.price}
                  />
                ) : (
                  <Typography sx={{ fontWeight: '700' }}>
                    {formatPrice(product.price)}
                  </Typography>
                )}
              </Box>
              <Box flex={1}>
                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    color: 'text.secondary',
                    fontWeight: '600',
                  }}
                >
                  Quantity
                </Typography>
                {edit ? (
                  <CustomTextField
                    name="quantity"
                    type="number"
                    min={0}
                    value={values.quantity}
                    onChange={handleChange}
                    error={error.quantity !== ''}
                    helperText={error.quantity}
                  />
                ) : (
                  <Typography sx={{ fontWeight: '700' }}>
                    {product.stock}
                  </Typography>
                )}
              </Box>
              <Box flex={1}>
                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    color: 'text.secondary',
                    fontWeight: '600',
                  }}
                >
                  Cost Per Unit
                </Typography>
                {edit ? (
                  <CustomTextField
                    name="costPerUnit"
                    type="number"
                    min={0}
                    value={values.cost}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <Typography sx={{ fontWeight: '700' }}>
                    {typeof product.cost === 'number'
                      ? formatPrice(product.cost)
                      : 'N/A'}
                  </Typography>
                )}
              </Box>
            </Box>
            {edit && (
              <div className="mt-4">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: 'none', mr: 2 }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ textTransform: 'none' }}
                  onClick={handleSave}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Saving' : 'Save'}
                </Button>
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  )
}

export default PricingDetails
