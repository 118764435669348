import { FC, useEffect, useState } from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import Label from 'components/forms/Label'
import QuillEditor from 'components/quill'
import CategoryInput from 'pages/dashboard/components/CategoryInput'
import { updateProduct } from 'services/products'
import { Product } from 'types/product'

interface EditBasicDetailsProps {
  product: Product
  onUpdate: (updatedProduct?: Product) => void
  onEditClose: () => void
}

interface FormValues {
  categoryIds: number[]
  description: string
}

const EditBasicDetails: FC<EditBasicDetailsProps> = ({
  product,
  onUpdate,
  onEditClose,
}) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    categoryIds: [],
    description: '',
  })

  useEffect(() => {
    setInitialValues({
      categoryIds: product.categoryIds,
      description: product.description || '',
    })
  }, [product])

  const validationSchema = Yup.object().shape({
    categoryIds: Yup.array().required('At least one category is required'),
    description: Yup.string().required('Description is required'),
  })

  const handleSubmit = async (values: FormValues) => {
    try {
      const response = await updateProduct({
        productId: product.id,
        dataUpdate: {
          categoryIds: values.categoryIds,
          description: values.description,
        },
      })
      if (response.status === 'successful' && response.data) {
        onUpdate(response.data)
        onEditClose()
      }
    } catch (error) {
      console.error('Failed to update product details:', error)
    }
  }

  const TotalVariant =
    product.ProductOption.length > 0
      ? `${product.ProductOption.length} Variants`
      : 'None'

  return (
    <Box
      className="m-5 p-5 rounded"
      sx={{ border: '1px solid', borderColor: 'accents.lightGrayAccent' }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <Form id="formik-form">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Label text="Category" color="secondary" />
                <CategoryInput
                  onChange={(ids) => setFieldValue('categoryIds', ids)}
                  defaultValues={values.categoryIds}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label text="Variants" color="secondary" />
                <Typography sx={{ fontWeight: '700' }}>
                  {TotalVariant}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Label text="Description" color="secondary" />
                <QuillEditor
                  value={values.description}
                  onChange={(val) => setFieldValue('description', val)}
                  limit={1000}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default EditBasicDetails
