import { ChangeEvent, FC, useEffect, useState } from 'react'

import { Button } from '@mui/material'

import Card from 'components/card'
import { useSnackbar } from 'hooks/useSnackbar'
import TagInput from 'pages/dashboard/components/TagInput'
import { updateProduct } from 'services/products'
import { Product } from 'types/product'

interface NotesAndTagsProps {
  onNextStep: () => void
  productId: number
  productNotes?: string | null
  productTags?: number[] | null
  onProductUpdate: (product: Product) => void
  onPrevStep: (step?: number) => void
  variantOptionsLength: number
}

const NotesAndTags: FC<NotesAndTagsProps> = ({
  onNextStep,
  productId,
  productNotes,
  productTags,
  onProductUpdate,
  onPrevStep,
  variantOptionsLength,
}) => {
  const [notes, setNotes] = useState('')
  const [tags, setTags] = useState<number[]>([])
  const { showSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (productNotes) {
      setNotes(productNotes)
    }
  }, [productNotes])

  const handleTagsChange = (tagsValues: number[]): void => {
    setTags(tagsValues)
  }

  const handleNotesChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setNotes(e.target.value)
  }

  const handleNotesTagsAdd = async (): Promise<void> => {
    if (
      productNotes?.trim() === notes &&
      tags.length === productTags?.length &&
      tags.every((value, index) => value === productTags?.[index])
    ) {
      onNextStep()
      return
    }
    setIsSubmitting(true)
    const res = await updateProduct({
      productId,
      dataUpdate: {
        notes,
        tagIds: tags,
      },
    })

    if (res.status === 'successful' && res.data) {
      onNextStep()
      setNotes('')
      setTags([])
      onProductUpdate(res.data)
      showSnackbar('Notes and tags added successfully', 'success')
    } else if (res.status === 'failed') {
      showSnackbar('Failed to add notes and tags', 'error')
    }
    setIsSubmitting(false)
  }

  return (
    <div className=" mb-5">
      <h2 className="text-lg font-semibold ml-5">Notes</h2>
      <p className="text-sm text-gray-500 mb-2 ml-5">
        Notes field is for internal use only. Customers will not see these
        notes.
      </p>
      <Card>
        <div className="mb-4">
          <textarea
            name="notes"
            className="w-full border border-gray-300 rounded py-2 px-3"
            rows={3}
            value={notes}
            onChange={handleNotesChange}
          />
        </div>
      </Card>
      <h2 className="text-lg font-semibold ml-5">Tags</h2>
      <p className="text-sm text-gray-500 mb-2 ml-5">
        Tags are used to categorize products.
      </p>
      <Card>
        <TagInput
          entity="product"
          onChange={handleTagsChange}
          defaultValues={productTags === null ? undefined : productTags}
        />
      </Card>
      <div className="mx-5">
        <div className="flex items-center w-full">
          <div className="flex-1 basis-1/2">
            {variantOptionsLength !== 0 && (
              <Button
                size="small"
                variant="outlined"
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => onPrevStep(5)}
              >
                Prev: Set Options
              </Button>
            )}
            {variantOptionsLength === 0 && (
              <Button
                size="small"
                variant="outlined"
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => onPrevStep(3)}
              >
                Prev: Add Variants
              </Button>
            )}
          </div>
          <div className="flex-1 basis-3/5">
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleNotesTagsAdd}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Adding...' : 'Next: Manage Status'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotesAndTags
