import { FC } from 'react'

import Card from 'components/card'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { Product, ProductOption } from 'types/product'

import PrimaryTable from './VariantPrimaryTable'
import VariantsTable from './VariantsTable'

interface VariantDetailsProps {
  variants?: ProductOption[]
  productCode: string
  onUpdate: (data?: Product) => void
}

const VariantsDetails: FC<VariantDetailsProps> = ({
  variants = [],
  productCode,
  onUpdate,
}) => {
  const primaryOptions = variants.find((obj) => {
    if (obj.type.toLowerCase() === 'primary') {
      obj.ProductOptionValue.sort((a, b) => a.id - b.id)
      return true
    }
    return false
  })

  const sizeOption = variants.find((obj) => {
    if (
      obj.name.toLowerCase() === 'size' &&
      obj.type.toLowerCase() === 'secondary'
    ) {
      obj.ProductOptionValue.sort((a, b) => a.id - b.id)
      return true
    }
    return false
  })

  const colorOptions = variants.find((obj) => {
    if (
      obj.name.toLowerCase() === 'color' &&
      obj.type.toLowerCase() === 'secondary'
    ) {
      obj.ProductOptionValue.sort((a, b) => a.id - b.id)
      return true
    }
    return false
  })

  const otherOptions = variants.filter((obj) => {
    if (
      obj.name.toLowerCase() !== 'color' &&
      obj.name.toLowerCase() !== 'size' &&
      obj.type.toLowerCase() === 'secondary'
    ) {
      obj.ProductOptionValue.sort((a, b) => a.id - b.id)
      return true
    }
    return false
  })

  return (
    <div>
      <div className="mx-5">
        <SubTabHeader title="Variants" />
      </div>
      {primaryOptions && (
        <PrimaryTable
          option={primaryOptions}
          productCode={productCode}
          onUpdate={onUpdate}
        />
      )}

      {sizeOption && (
        <Card>
          <VariantsTable
            optionData={sizeOption}
            productCode={productCode}
            onUpdate={onUpdate}
          />
        </Card>
      )}

      {colorOptions && (
        <Card>
          <VariantsTable
            optionData={colorOptions}
            productCode={productCode}
            onUpdate={onUpdate}
          />
        </Card>
      )}

      {otherOptions.length > 0 &&
        otherOptions.map((option) => (
          <Card key={option.id}>
            <VariantsTable
              optionData={option}
              productCode={productCode}
              onUpdate={onUpdate}
            />
          </Card>
        ))}
    </div>
  )
}

export default VariantsDetails
