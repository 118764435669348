import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Label from 'components/forms/Label'

interface AccordionHeaderProps {
  productId: number
  totalVariants: string
  productName: string
  lastUpdated: string
  lastUpdatedBy: string
  expanded: boolean
}

const StockAccordionHeader: React.FC<AccordionHeaderProps> = ({
  productId,
  productName,
  totalVariants,
  lastUpdated,
  lastUpdatedBy,
  expanded,
}) => {
  const navigate = useNavigate()

  const handleNavigateToProduct = () => {
    navigate(`/dashboard/products/${productId}`)
  }

  return (
    <>
      <ExpandMoreIcon
        sx={{
          transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.3s ease-in-out',
          marginRight: 2,
        }}
      />

      <Box
        className="flex-1 sm:mr-20"
        sx={{
          display: { xs: 'flex', sm: 'flex-1' },
          justifyContent: { xs: 'space-between' },
          alignItems: { xs: 'center' },
          gap: 2,
        }}
      >
        <Box>
          <Label text="Product" color="secondary" />
          <Typography
            className="inline-block hover:underline font-bold"
            onClick={handleNavigateToProduct}
          >
            {productName}
          </Typography>
        </Box>
        <Box
          sx={{
            marginLeft: { xs: 2, sm: 0 },
          }}
        >
          <Label text="Variants" color="secondary" />
          <Typography className="inline-block hover:underline font-bold">
            {totalVariants}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          textAlign: 'left',
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <Label text="Last Updated At" color="secondary" />

        <Typography>{lastUpdated}</Typography>
      </Box>
      <Box
        sx={{
          flex: 0.5,
          textAlign: 'left',
          marginLeft: 2,
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <Label text=" Last Updated By" color="secondary" />
        <Typography>{lastUpdatedBy}</Typography>
      </Box>
    </>
  )
}

export default StockAccordionHeader
