import { useEffect, useState } from 'react'

import { DataGridTable } from 'pages/dashboard/components/StyledDataGrid'
import { getAllProducts } from 'services/products'
import { Product } from 'services/products/index.types'
import { dateTimeFormat } from 'utils/dateFormat'
import { formatPrice } from 'utils/helper'

import { columns } from './Columns'

interface ProductListProps {
  orgId: number
}

const ProductList: React.FC<ProductListProps> = ({ orgId }): JSX.Element => {
  const [list, setList] = useState<Product[]>([])

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const queryOptions = {
        orgId,
      }
      const result = await getAllProducts(queryOptions)
      if (result.status === 'successful' && result.data) {
        setList(result.data)
      }
    }
    fetchData()
  }, [orgId])

  const rows = list.map((product) => ({
    productCode: product.productCode,
    id: product.id,
    title: product.name,
    price: formatPrice(product.price),
    offerPrice:
      product.variants && product.variants.length > 0
        ? product.variants
            .filter(
              (variant) =>
                variant.offerValidity && variant.offerValidity.length > 0
            )
            .map((variant) => formatPrice(variant.discountedPrice))[0] || 'N/A'
        : product.offerValidity && product.offerValidity.length > 0
          ? formatPrice(product.discountedPrice) || 'N/A'
          : 'N/A',
    status: `${product.isActive ? 'Yes' : 'No'} / ${product.isListed ? 'Yes' : 'No'}`,
    createdBy: product.createdByUser
      ? `${product.createdByUser.firstName} ${product.createdByUser.lastName}`
      : 'N/A',
    createdAt: product.createdByUser
      ? `${product.createdByUser.firstName}, ${dateTimeFormat(product.createdAt)}`
      : 'N/A',
    updatedAt: product.createdByUser
      ? `${product.createdByUser.firstName}, ${dateTimeFormat(product.updatedAt)}`
      : 'N/A',
  }))

  return (
    <div className="mx-5">
      <DataGridTable
        rows={rows}
        columns={columns}
        pageSizeOptions={[25, 50, 100]}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnMenu
        pagination
        // paginationMode="server"
      />
    </div>
  )
}

export default ProductList
