import React, { useEffect, useState } from 'react'

import { Button, Grid } from '@mui/material'

import Card from 'components/card'
import FileUpload from 'components/files/FileUpload'
import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import DisplayImage from 'pages/dashboard/components/DisplayImage'
import { deleteFile } from 'services/files'
import { getProduct, updateProduct } from 'services/products'
import { Product } from 'types/product'

interface ProductFilesProps {
  productId?: number
  onFilesAdd?: (files: string[]) => void
  onNextStep?: () => void
  onPrevStep?: (step?: number) => void
}

const fileUrl = `${process.env.REACT_APP_FILE_URL}`

const ProductFiles: React.FC<ProductFilesProps> = ({
  productId,
  onFilesAdd,
  onNextStep,
  onPrevStep,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([])
  const [productData, setProductData] = useState<Product>()
  const { organizationId } = useAuth()

  let mediaFiles: string[] = []
  if (productData !== undefined) {
    mediaFiles = productData.media.map((m) => m.split('/')[3])
  }
  mediaFiles = [...mediaFiles, ...uploadedFiles]

  useEffect(() => {
    if (uploadedFiles.length === 0) return
    if (productId && organizationId) {
      updateProductFiles(productId, uploadedFiles)
      Amplitude.trackEvent('PRODUCT_PICTURES_AND_VIDEOS_FILE_ADDED', {
        productId: productId,
        orgId: organizationId,
      })
    }
  }, [uploadedFiles, productId, organizationId])

  useEffect(() => {
    if (onFilesAdd) onFilesAdd(uploadedFiles)
  }, [uploadedFiles, onFilesAdd])

  const updateProductFiles = async (
    productId: number,
    uploadedFiles: string[]
  ): Promise<void> => {
    const mergeFiles = uploadedFiles.map((file) => `${fileUrl}/${file}`)
    if (productData !== undefined) mergeFiles.push(...productData.media)

    const res = await updateProduct({
      productId,
      dataUpdate: {
        media: mergeFiles,
      },
    })

    if (res.status === 'successful') {
      setProductData(res.data)
      setUploadedFiles([])
    }

    if (productId && organizationId) {
      Amplitude.trackEvent('PRODUCT_PICTURES_AND_VIDEOS_SUBMIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
  }

  const getProductDetails = async (productId: number): Promise<void> => {
    const res = await getProduct({ id: productId })
    if (res.status === 'successful') {
      setProductData(res.data)
    }
  }

  useEffect(() => {
    if (productId) {
      getProductDetails(productId)
    }
  }, [productId])

  const onDelete = async (fileName: string): Promise<void> => {
    const res = await deleteFile(fileName)
    if (res.status === 'successful') {
      if (productId) {
        const filterFiles = mediaFiles.filter((f) => f !== fileName)
        const result = await updateProduct({
          productId,
          dataUpdate: {
            media: filterFiles.map((file) => `${fileUrl}/${file}`),
          },
        })
        if (result.status === 'successful') {
          setProductData(result.data)
        }
      }

      if (productId && organizationId) {
        Amplitude.trackEvent('PRODUCT_PICTURES_AND_VIDEOS_DELETE_CLICKED', {
          productId: productId,
          orgId: organizationId,
        })
      }

      const currentUploadedFiles = uploadedFiles.filter((f) => f !== fileName)
      setUploadedFiles(currentUploadedFiles)
    }
  }

  return (
    <div className="mb-5">
      <h2 className="text-lg font-semibold ml-5">Product Images</h2>
      <p className="text-sm text-gray-500 mb-2 ml-5">
        Select images of the product from your device, or simply drag and drop
        them here.
      </p>
      <Card>
        {mediaFiles && mediaFiles.length > 0 && (
          <Grid container spacing={1} marginBottom={2}>
            {mediaFiles.map((fileName, index) => (
              <Grid item key={index} xs={3} sm={2} md={1}>
                <DisplayImage
                  imageUrl={`${process.env.REACT_APP_FILE_URL}/${fileName}`}
                  onDelete={() => onDelete(fileName)}
                  height={200}
                />
              </Grid>
            ))}
          </Grid>
        )}

        <FileUpload
          onClose={() => {}}
          onSuccess={(file: string) => setUploadedFiles((f) => [...f, file])}
        />
      </Card>
      {onNextStep && (
        <div className="mx-5">
          <div className="flex items-center w-full">
            <div className="flex-1 basis-1/2">
              <Button
                size="small"
                variant="outlined"
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => onPrevStep?.(undefined)}
              >
                Prev: Add Basic Details
              </Button>
            </div>
            <div className="flex-1 basis-3/5">
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  textTransform: 'none',
                }}
                onClick={onNextStep}
              >
                Next : Add Variants
              </Button>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Button
              variant="text"
              size="small"
              onClick={onNextStep}
              sx={{
                textTransform: 'none',
                textDecoration: 'underline',
                display: 'block',
                mt: 1,
              }}
              disabled={mediaFiles && mediaFiles.length > 0}
            >
              This product has no images, skip to next step {'>>'}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductFiles
