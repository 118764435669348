import InfoIcon from '@mui/icons-material/Info'
import { Tooltip } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import CustomCell from 'components/table/CustomCell'

export const columns: GridColDef[] = [
  { field: 'productCode', headerName: 'Code', width: 80 },
  {
    field: 'title',
    headerName: 'Title',
    width: 400,
    renderCell: (params: GridRenderCellParams) => (
      <CustomCell {...params} route="products" />
    ),
  },
  { field: 'price', headerName: 'Price', width: 100 },
  {
    field: 'offerPrice',
    headerName: 'Offer Price',
    width: 100,
  },
  {
    field: 'status',
    headerName: 'Is Active / Is Listed',
    width: 200,
    renderHeader: (params) => (
      <div>
        <span className="font-bold">{params.colDef.headerName}</span>
        <Tooltip title="Shows if the product is active and if it is listed">
          <InfoIcon sx={{ ml: 1, fontSize: 16 }} />
        </Tooltip>
      </div>
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 250,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 250,
  },
]
