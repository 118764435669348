import { ChangeEvent, FC, SyntheticEvent, useState } from 'react'

import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonBase,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'

import Label from 'components/forms/Label'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { checkCourierServiceability } from 'services/delivery'
import { CourierData } from 'services/delivery/index.types'

import CompanyAccordion from './CompanyAccordion'

export const companiesUrl = [
  { id: 33, url: 'https://www.xpressbees.com/' },
  { id: 51, url: 'https://www.xpressbees.com/' },
  { id: 10, url: 'https://www.delhivery.com/' },
  { id: 196, url: 'https://www.dtdc.in/' },
  { id: 6, url: 'https://www.dtdc.in/' },
  { id: 142, url: 'https://track.amazon.in/' },
  { id: 14, url: 'https://www.ecomexpress.in/' },
  { id: 19, url: 'https://www.ecomexpress.in/' },
  { id: 228, url: 'https://www.ecomexpress.in/' },
  { id: 411, url: 'https://www.shiprocket.in/' },
  { id: 54, url: 'https://www.ekartlogistics.in/b2c-express' },
  { id: 48, url: 'https://www.ekartlogistics.in/b2c-express' },
  {
    id: 217,
    url: 'https://www.indiapost.gov.in/MBE/Pages/Content/Speed-Post.aspx',
  },
  {
    id: 225,
    url: 'https://www.indiapost.gov.in/MBE/Pages/Content/Speed-Post.aspx',
  },
]
interface CourierServiceDialogProps {
  isOpen: boolean
  pickupCode: string
  deliveryCode: string
  onClose: () => void
}

const CourierServiceDialog: FC<CourierServiceDialogProps> = ({
  isOpen,
  deliveryCode,
  pickupCode,
  onClose,
}) => {
  const [courierData, setCourierData] = useState<CourierData>()
  const [values, setValues] = useState({
    deliveryPostcode: deliveryCode,
    pickupPostcode: pickupCode,
    weight: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSnackbar } = useSnackbar()
  const [error, setError] = useState<string>()
  const [expanded, setExpanded] = useState(false)
  const [sortBy, setSortBy] = useState('deliveryPerformance')

  const handleSortChange = (event: SelectChangeEvent<string>): void => {
    setSortBy(event.target.value)
  }

  const handleAccordionExpandChange = (
    event: SyntheticEvent,
    isExpanded: boolean
  ): void => {
    setExpanded(isExpanded)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'deliveryPostcode') {
      setValues((prev) => ({ ...prev, deliveryPostcode: e.target.value }))
    } else if (e.target.name === 'pickupPostcode') {
      setValues((prev) => ({ ...prev, pickupPostcode: e.target.value }))
    } else {
      setValues((prev) => ({ ...prev, weight: e.target.value }))
    }
  }

  const handleClick = async (): Promise<void> => {
    const { deliveryPostcode, pickupPostcode, weight } = values

    if (
      deliveryPostcode.trim() === '' ||
      pickupPostcode.trim() === '' ||
      weight.trim() === ''
    ) {
      setError('*Enter all fields correctly.')
      return
    }

    setIsSubmitting(true)

    const res = await checkCourierServiceability({ ...values, cod: 0 })

    if (res.status === 'successful' && res.data) {
      setCourierData(res.data)
    } else if (res.status === 'failed' && res.message) {
      showSnackbar(res.message, 'error')
    }
    setIsSubmitting(false)
    setError(undefined)
  }

  const recommendedCompany = courierData?.data.available_courier_companies.find(
    (company) =>
      company.courier_company_id ===
      courierData?.data.shiprocket_recommended_courier_id
  )

  const courierCompanies = courierData?.data.available_courier_companies
    .filter((company) => company.id !== recommendedCompany?.id)
    .sort((aComp, bComp) => {
      switch (sortBy) {
        case 'deliveryPerformance':
          return bComp.delivery_performance - aComp.delivery_performance
        case 'pickupPerformance':
          return bComp.pickup_performance - aComp.pickup_performance
        case 'price':
          return aComp.freight_charge - bComp.freight_charge
        case 'estDate':
          return (
            Number(aComp.estimated_delivery_days) -
            Number(bComp.estimated_delivery_days)
          )
        default:
          return 0
      }
    })

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <DialogTitle>
        <div className="flex items-center justify-between">
          <div className=" text-xl font-bold">Check Courier Serviceability</div>
          <IconButton
            aria-label="close"
            sx={{ color: 'black' }}
            onClick={onClose}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          {error && <p className=" text-red-500 text-sm mb-3">{error}</p>}
          {!courierData && (
            <>
              <Grid container columnSpacing={4} rowSpacing={2}>
                <Grid item sm={6}>
                  <Label text="Pickup Pin Code" color="secondary" />
                  <CustomTextField
                    id="pickupPostcode"
                    name="pickupPostcode"
                    value={values.pickupPostcode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Label text="Delivery Pin Code" color="secondary" />
                  <CustomTextField
                    id="deliveryPostcode"
                    name="deliveryPostcode"
                    value={values.deliveryPostcode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Label text="Estimated Weight (kg)" color="secondary" />
                  <CustomTextField
                    id="estWeight"
                    name="estWeight"
                    value={values.weight}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <div className=" flex justify-center mt-8">
                <Button
                  variant="contained"
                  sx={{ textTransform: 'none' }}
                  onClick={handleClick}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    'Checking'
                  ) : (
                    <>
                      Get Courier Options
                      <ArrowRightAltOutlinedIcon sx={{ marginLeft: 1 }} />
                    </>
                  )}
                </Button>
              </div>
            </>
          )}

          {courierData && courierCompanies && (
            <div>
              <Box
                id="values-header"
                className=" flex justify-between mb-5 gap-2 mt-2"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ sm: 'items-center' }}
                overflow={'visible'}
              >
                <div>
                  <Chip
                    icon={<LocationOnOutlinedIcon />}
                    label={`Pickup: ${values.pickupPostcode}`}
                    variant="outlined"
                    size="small"
                    sx={{ mr: 1 }}
                  />
                  <Chip
                    icon={<LocationOnOutlinedIcon />}
                    label={`Delivery: ${values.deliveryPostcode}`}
                    variant="outlined"
                    size="small"
                    sx={{ mr: 1 }}
                  />
                  <Chip
                    label={`Weight: ${values.weight}kg`}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <Box className="flex" marginTop={{ xs: 2, sm: 0 }}>
                  <FormControl
                    sx={{
                      margin: 0,
                      '& .MuiSelect-select': {
                        padding: 1,
                        display: 'inline-flex',
                        alignItems: 'center',
                      },
                      marginRight: 2,
                    }}
                  >
                    <InputLabel id="sort-by-label">Sort By</InputLabel>
                    <Select
                      labelId="sort-by-label"
                      value={sortBy}
                      onChange={handleSortChange}
                      label="Sort By"
                      sx={{ fontSize: '12px' }}
                    >
                      <MenuItem
                        value="deliveryPerformance"
                        sx={{
                          fontSize: '0.8rem',
                          '&:hover': {
                            backgroundColor: 'gray',
                          },
                        }}
                      >
                        Delivery Performance
                      </MenuItem>
                      <MenuItem
                        value="pickupPerformance"
                        sx={{
                          fontSize: '0.8rem',
                          '&:hover': {
                            backgroundColor: 'gray',
                          },
                        }}
                      >
                        Pickup Performance
                      </MenuItem>
                      <MenuItem
                        value="price"
                        sx={{
                          fontSize: '0.8rem',
                          '&:hover': {
                            backgroundColor: 'gray',
                          },
                        }}
                      >
                        Freight Charges
                      </MenuItem>
                      <MenuItem
                        value="estDate"
                        sx={{
                          fontSize: '0.8rem',
                          '&:hover': {
                            backgroundColor: 'gray',
                          },
                        }}
                      >
                        Delivery Date
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <ButtonBase
                    sx={{ textDecoration: 'underline' }}
                    onClick={() => setCourierData(undefined)}
                  >
                    Clear
                  </ButtonBase>
                </Box>
              </Box>
              {recommendedCompany && (
                <div>
                  <div className="border-2 border-solid mt-3 px-4 py-1 bg-green-100 border-green-600 text-green-700 rounded-t font-semibold flex items-center justify-between">
                    <p>Recommended</p>
                    <CheckOutlinedIcon />
                  </div>

                  <Accordion
                    key={recommendedCompany.id}
                    elevation={0}
                    expanded={expanded}
                    onChange={handleAccordionExpandChange}
                    sx={{
                      border: '2px solid',
                      borderColor: '#16a34a',
                      borderBottomLeftRadius: '4px',
                      borderBottomRightRadius: '4px',
                      marginBottom: '12px',
                      '&::before ': {
                        content: 'none',
                      },
                      borderTop: 'none',
                      '&.Mui-expanded': {
                        margin: 0,
                      },
                    }}
                  >
                    <AccordionSummary
                      aria-controls="panel2-content"
                      id="panel2-header"
                      sx={{
                        '.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded':
                          {
                            marginBottom: 0,
                          },
                      }}
                    >
                      <div className="w-full">
                        <div className=" flex items-center justify-between">
                          <Typography fontWeight={600} mb={1}>
                            <a
                              href={
                                companiesUrl.find(
                                  (el) =>
                                    el.id ===
                                    recommendedCompany.courier_company_id
                                )?.url
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {recommendedCompany.courier_name}
                            </a>
                          </Typography>
                          <ArrowDropUpOutlinedIcon
                            sx={{
                              transition: 'transform 0.3s',
                              transform: expanded
                                ? 'rotate(0deg)'
                                : 'rotate(180deg)',
                            }}
                          />
                        </div>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={4}>
                            <Label
                              text="Estimated Delivery"
                              color="secondary"
                            />
                            <Typography>{`${recommendedCompany.estimated_delivery_days} days (${recommendedCompany.etd})`}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Label text="Freight Charges" color="secondary" />
                            <Typography>
                              ₹{recommendedCompany.freight_charge}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Label
                              text="Max Weight Supported"
                              color="secondary"
                            />
                            <Typography>
                              {recommendedCompany.mode
                                ? recommendedCompany.air_max_weight
                                : recommendedCompany.surface_max_weight}{' '}
                              kg
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Label
                              text="Pickup Performance"
                              color="secondary"
                            />
                            <Typography>
                              {recommendedCompany.pickup_performance}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Label
                              text="Delivery Performance"
                              color="secondary"
                            />
                            <Typography>
                              {recommendedCompany.delivery_performance}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Label text="Tracking" color="secondary" />
                            <Typography>
                              {recommendedCompany.realtime_tracking}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ width: '100%' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <Label text="COD Charges" color="secondary" />
                          <Typography>
                            ₹{recommendedCompany.cod_charges}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Label text="RTO Charges" color="secondary" />
                          <Typography>
                            ₹{recommendedCompany.rto_charges}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Label
                            text="Call Before Delivery"
                            color="secondary"
                          />
                          <Typography>
                            {recommendedCompany.call_before_delivery}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
              {courierCompanies.map((company) => (
                <CompanyAccordion key={company.id} company={company} />
              ))}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default CourierServiceDialog
