import React, { useState } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Rating,
  ImageListItem,
  ImageList,
  Box,
} from '@mui/material'
import { formatDistanceToNow } from 'date-fns'
import { useNavigate } from 'react-router-dom'

interface ReviewItemProps {
  id: number
  name: string
  productName: string
  productId: number
  rating: number
  createdAt: string
  reviewTitle?: string
  reviewText?: string
  reviewStatus: string
  media: Array<string>
}

const ReviewsList: React.FC<{
  reviews: ReviewItemProps[]
  activeTab: string
  orgId: number
  onStatusChange: (reviewId: number, status: string) => void
}> = ({ reviews, activeTab, onStatusChange }) => {
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState<number | null>(null)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const handleRowClick = (id: number) => {
    setExpanded(expanded === id ? null : id)
  }

  const handleNavigateToProduct = (id: number) => {
    navigate(`/dashboard/products/${id}`)
  }

  return (
    <TableContainer
      component={Paper}
      className="rounded shadow-md overflow-x-auto"
    >
      <Box sx={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Summary</strong>
              </TableCell>
              <TableCell>
                <strong>Rating</strong>
              </TableCell>
              <TableCell>
                <strong>Created</strong>
              </TableCell>
              <TableCell>
                <strong>Actions</strong>
              </TableCell>
              <TableCell>
                <strong>{''}</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reviews.map((review) => (
              <React.Fragment key={review.id}>
                <TableRow
                  onClick={() => handleRowClick(review.id)}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <TableCell>
                    <Typography noWrap>
                      <strong className="underline">{review.name}</strong> wrote
                      a review about{' '}
                      <strong
                        className="underline cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleNavigateToProduct(review.productId)
                        }}
                      >
                        {review.productName}
                      </strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Rating
                      value={review.rating}
                      size="small"
                      readOnly
                      precision={0.5}
                    />
                  </TableCell>
                  <TableCell>
                    {' '}
                    {formatDistanceToNow(new Date(review.createdAt), {
                      addSuffix: true,
                    })}
                  </TableCell>
                  <TableCell>
                    {activeTab === 'pending' && (
                      <>
                        <Typography
                          component="span"
                          sx={{
                            color: '#00796b',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            '&:hover': { textDecoration: 'underline' },
                          }}
                          onClick={() => onStatusChange(review.id, 'approved')}
                        >
                          Approve
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            color: '#d32f2f',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            marginLeft: 2,
                            '&:hover': { textDecoration: 'underline' },
                          }}
                          onClick={() => onStatusChange(review.id, 'rejected')}
                        >
                          Reject
                        </Typography>
                      </>
                    )}
                    {activeTab === 'approved' && (
                      <Typography
                        sx={{
                          color: '#00796b',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                        className="hover:underline"
                        onClick={() => onStatusChange(review.id, 'rejected')}
                      >
                        Unpublish
                      </Typography>
                    )}
                    {activeTab === 'rejected' && (
                      <Typography
                        sx={{
                          color: '#00796b',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                        className="hover:underline"
                        onClick={() => onStatusChange(review.id, 'approved')}
                      >
                        Publish
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <ExpandMoreIcon
                      sx={{
                        transform:
                          expanded === review.id
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  </TableCell>
                </TableRow>
                {expanded === review.id && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Accordion expanded={true}>
                        <AccordionDetails
                          sx={{
                            backgroundColor: '#F4F7F6',
                          }}
                        >
                          <Typography variant="h6" fontWeight="bold">
                            {review.reviewTitle}
                          </Typography>
                          <Typography className="mb-1">
                            {review.reviewText ||
                              'No additional text provided.'}
                          </Typography>
                          {review.media && review.media.length > 0 && (
                            <ImageList
                              cols={4}
                              className="mt-4"
                              sx={{ width: { lg: 500 } }}
                            >
                              {review.media.map((imgUrl, imgIndex) => (
                                <ImageListItem
                                  key={imgIndex}
                                  onClick={() => setActiveIndex(imgIndex)}
                                >
                                  <img
                                    src={imgUrl}
                                    height={75}
                                    className={`cursor-pointer object-contain ${
                                      activeIndex === imgIndex
                                        ? 'opacity-100'
                                        : 'opacity-90'
                                    } hover:opacity-100`}
                                    alt={`Thumbnail ${imgIndex}`}
                                  />
                                </ImageListItem>
                              ))}
                            </ImageList>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  )
}

export default ReviewsList
