import { useState, useEffect } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, IconButton, TextField, Button, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import {
  getCategoryById,
  updateExistingCategory,
} from 'services/products/categories'

import CategoryDetailsSection from './CategoryDetailSection'

const validationSchema = Yup.object({
  name: Yup.string().required('Title is required'),
})

const DetailsPage = () => {
  const { categoryId } = useParams<{ categoryId: string }>()
  const { organizationId } = useAuth()

  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const [description, setDescription] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [isEditing, setIsEditing] = useState(false)
  const [editedTitle, setEditedTitle] = useState(title)
  const [error, setError] = useState('')
  const { showSnackbar } = useSnackbar()

  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate(-1)
  }

  const handleSaveTitle = async (newTitle: string) => {
    if (!categoryId) {
      console.error('Category ID is missing. Cannot update category.')
      return
    }

    const updateData = { name: newTitle }

    try {
      const result = await updateExistingCategory(categoryId, updateData)

      if (result.status === 'successful') {
        setTitle(newTitle)
      } else {
        console.error('Failed to update category:', result.message)
      }
    } catch (error) {
      console.error('Error during update:', error)
    }
  }

  const handleSaveDescription = async (): Promise<void> => {
    if (!categoryId) {
      console.error('Category ID is missing. Cannot update category.')
      return
    }
    if (organizationId) {
      Amplitude.trackEvent('CATEGORY_DETAILS_SAVE_CLICKED', {
        orgId: organizationId,
      })
    }
    const updateData = {
      description: description,
    }

    const result = await updateExistingCategory(categoryId, updateData)

    if (result.status === 'successful') {
      setDescription(description)
    }
    setIsEditing(false)
  }

  const handleEdit = () => {
    if (organizationId) {
      Amplitude.trackEvent('CATEGORY_TITLE_EDIT_CLICKED', {
        orgId: organizationId,
      })
    }
    setIsEditing(true)
  }

  const handleSave = async (): Promise<void> => {
    if (organizationId) {
      Amplitude.trackEvent('CATEGORY_TITLE_SAVE_CLICKED', {
        orgId: organizationId,
      })
    }
    try {
      await validationSchema.validate({ name: editedTitle })
      handleSaveTitle(editedTitle)
      handleSaveDescription()
      setIsEditing(false)
      setError('')
      showSnackbar('Category updated successfully', 'success')
    } catch (validationError) {
      if (validationError instanceof Yup.ValidationError) {
        setError(validationError.message)
        showSnackbar(validationError.message, 'error')
      } else {
        showSnackbar(
          'An unexpected error occurred while saving the category',
          'error'
        )
      }
    }
  }

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      if (!categoryId || !organizationId) return

      const result = await getCategoryById(
        organizationId.toString(),
        categoryId
      )

      if (result.status === 'successful') {
        const { name, description, imageUrl } = result.data
        setTitle(name)
        setDescription(description)
        setImageUrl(imageUrl)
      } else {
        console.error('Failed to fetch category:', result.message)
      }
    }

    fetchCategoryDetails()
  }, [categoryId, organizationId])

  useEffect(() => {
    if (title !== editedTitle && !isEditing) {
      setEditedTitle(title)
    }
  }, [title, editedTitle, isEditing])

  return (
    <div>
      <Box
        sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
        className="mx-3 mt-4"
      >
        <IconButton onClick={handleBackClick} sx={{ color: 'text.primary' }}>
          <ArrowBackIcon />
        </IconButton>

        {isEditing ? (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <TextField
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              size="small"
              fullWidth
              error={!!error}
              helperText={error}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ ml: 1 }}
              size="small"
            >
              Save
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography variant="h6" sx={{ flex: 1 }}>
              {title}
            </Typography>
            <Button variant="outlined" size="small" onClick={handleEdit}>
              Edit
            </Button>
          </Box>
        )}
      </Box>

      <CategoryDetailsSection
        description={description}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setDescription={setDescription}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </div>
  )
}

export default DetailsPage
