import { FC, useEffect, useRef } from 'react'

import { Box, Grid, MenuItem, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import Label from 'components/forms/Label'
import AddressInput, {
  AddressType,
} from 'pages/dashboard/components/AddressInput'
import CityInput from 'pages/dashboard/components/CityInput'
import PhoneNumberInput from 'pages/dashboard/components/PhoneNumberInput'
import { getRestrictedCountriesList, getStatesOfCountry } from 'utils/countries'

import { AddressFormType } from './AddressDialog'

interface CustomerAddressProps {
  onAddressSelect: (data: AddressType) => void
  countryCodeValue?: string
  phoneNoValue?: string
}

const Address: FC<CustomerAddressProps> = ({
  onAddressSelect,
  countryCodeValue,
  phoneNoValue,
}): JSX.Element => {
  const addressInputRef = useRef<HTMLInputElement>(null)
  const cityInputRef = useRef<HTMLInputElement>(null)
  const countriesList = getRestrictedCountriesList()

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<AddressFormType>()

  const countryValue = watch('country')
  const restrictTo = countriesList.find(
    (country) => country.name === countryValue
  )?.isoCode

  const statesList = getStatesOfCountry(restrictTo)

  useEffect(() => {
    if (countryCodeValue && watch('countryCode') === '') {
      setValue('countryCode', countryCodeValue)
    }
    const currentPhoneNoValue = watch('phoneNo')
    if (
      (phoneNoValue && currentPhoneNoValue === '') ||
      (phoneNoValue && phoneNoValue.startsWith(currentPhoneNoValue))
    ) {
      setValue('phoneNo', phoneNoValue)
    }
  }, [countryCodeValue, phoneNoValue, setValue, watch])

  //Auto complete fields
  const addressHandler = (fieldsData: AddressType | null): void => {
    if (fieldsData === null) {
      setValue('addressLine1', '')
      return
    }
    onAddressSelect(fieldsData)
    setValue('addressLine1', fieldsData.address1, {
      shouldValidate: true,
    })
    setValue('city', fieldsData.city, { shouldValidate: true })
    setValue('state', fieldsData.state, { shouldValidate: true })
    setValue('pinCode', fieldsData.postalCode)
  }

  const citySelectHandler = (
    fieldsData: Omit<AddressType, 'address1'> | null
  ): void => {
    if (fieldsData === null) {
      setValue('city', '')
      return
    }
    setValue('city', fieldsData.city, { shouldValidate: true })
    setValue('state', fieldsData.state, { shouldValidate: true })
    if (fieldsData.postalCode)
      setValue('pinCode', fieldsData.postalCode, {
        shouldValidate: true,
      })
  }

  return (
    <Box>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Label htmlFor="country" text="Country" />
            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <TextField
                  SelectProps={{ style: { backgroundColor: 'white' } }}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  sx={{ marginTop: 0 }}
                  fullWidth
                  id="country"
                  variant="outlined"
                  margin="normal"
                  size="small"
                  error={errors.country && true}
                  helperText={errors.country && errors.country.message}
                  select
                >
                  {countriesList.map((val) => (
                    <MenuItem
                      key={val.name}
                      value={val.name}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'lightgray',
                        },
                      }}
                    >
                      {`${val.flagIcon} ${val.name}`}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Label htmlFor="firstName" text="First Name" />
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <TextField
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  sx={{ marginTop: 0 }}
                  fullWidth
                  id="firstName"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  error={errors.firstName && true}
                  helperText={errors.firstName && errors.firstName.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Label htmlFor="lastName" text="Last Name" />
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <TextField
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  sx={{ marginTop: 0 }}
                  fullWidth
                  id="lastName"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  placeholder="(optional)"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Label htmlFor="addressLine1" text="Address" />
            <Controller
              name="addressLine1"
              control={control}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <AddressInput
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  restrictTo={restrictTo}
                  inputRef={addressInputRef}
                  onAddressSelect={addressHandler}
                  error={errors.addressLine1 && true}
                  helperText={
                    errors.addressLine1 && errors.addressLine1.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Label htmlFor="addressLine2" text="Apartment, suite, etc." />
            <Controller
              name="addressLine2"
              control={control}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <TextField
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  sx={{ marginTop: 0 }}
                  fullWidth
                  id="addressLine2"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  error={errors.addressLine2 && true}
                  helperText={
                    errors.addressLine2 && errors.addressLine2.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label htmlFor="city" text="City" />
            <Controller
              name="city"
              control={control}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <CityInput
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  value={value ?? ''}
                  restrictTo={restrictTo}
                  inputRef={cityInputRef}
                  onCitySelect={citySelectHandler}
                  error={errors.city && true}
                  helperText={errors.city && errors.city.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label htmlFor="state" text="State" />
            <Controller
              name="state"
              control={control}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <TextField
                  SelectProps={{ style: { backgroundColor: 'white' } }}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  value={value ?? ''}
                  sx={{ marginTop: 0 }}
                  fullWidth
                  id="state"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  error={errors.state && true}
                  helperText={errors.state && errors.state.message}
                  select
                >
                  {statesList.length === 0 && (
                    <MenuItem disabled>Choose country to select state</MenuItem>
                  )}
                  {statesList.map((val) => (
                    <MenuItem
                      key={val.name}
                      value={val.name}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'lightgray',
                        },
                      }}
                    >
                      {val.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label htmlFor="pinCode" text="Pin Code" />
            <Controller
              name="pinCode"
              control={control}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <TextField
                  inputProps={{ style: { backgroundColor: 'white' } }}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  sx={{ marginTop: 0 }}
                  fullWidth
                  id="pinCode"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  error={errors.pinCode && true}
                  helperText={errors.pinCode && errors.pinCode.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Label htmlFor="phoneNo" text="Phone Number" />
            <Controller
              name="countryCode"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <PhoneNumberInput
                  countriesList={countriesList}
                  countryCodeName="countryCode"
                  phoneNumberName="phoneNo"
                  value={{
                    countryCode: value,
                    phoneNumber: watch('phoneNo'),
                  }}
                  error={{
                    countryCode: errors.countryCode?.message,
                    phoneNumber: errors.phoneNo?.message,
                  }}
                  helperText={{
                    countryCode: errors.countryCode?.message,
                    phoneNumber: errors.phoneNo?.message,
                  }}
                  onChange={(e) => {
                    if (e.target.name === 'countryCode') onChange(e)
                    else setValue('phoneNo', e.target.value)
                  }}
                  onBlur={onBlur}
                />
              )}
            />
          </Grid>
        </Grid>
      </>
    </Box>
  )
}

export default Address
