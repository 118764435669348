import { FC, useEffect, useState } from 'react'

import { Button, Chip, Stack, Typography } from '@mui/material'

import Card from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import TagInput from 'pages/dashboard/components/TagInput'
import { updateProduct } from 'services/products'
import { getTags } from 'services/tags'
import { Product } from 'types/product'
import { Tag } from 'types/tag'

interface TagDetailsProps {
  productId: number
  tagIds: number[]
  onUpdate?: (data: Product) => void
}

const TagsDetails: FC<TagDetailsProps> = ({ productId, tagIds, onUpdate }) => {
  const [tags, setTags] = useState<number[]>([])
  const [tagNames, setTagNames] = useState<string[]>([])
  const [edit, setEdit] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { organizationId } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const get = async (orgId: number): Promise<void> => {
      const result = await getTags('product', orgId)
      if (result && result.status === 'successful') {
        const data = result.data.filter((el: Tag) => tagIds.includes(el.id))
        const namesList = data.map((el: Tag) => el.name)
        setTagNames(namesList)
      }
    }
    if (organizationId) get(organizationId)
  }, [organizationId, tagIds])

  const handleEdit = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_TAGS_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    setEdit(true)
  }

  const handleTagsChange = (tags: number[]): void => {
    setTags(tags)
  }

  const handleSave = async (): Promise<void> => {
    if (organizationId)
      Amplitude.trackEvent('PRODUCT_TAGS_SAVE_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })

    setIsSubmitting(true)
    const result = await updateProduct({
      productId,
      dataUpdate: { tagIds: tags },
    })

    if (result.status === 'successful' && result.data) {
      setTags(result.data.tagIds)
      showSnackbar('Tags updated.', 'success')
      onUpdate?.(result.data)
    }
    setIsSubmitting(false)
    setEdit(false)
  }

  const handleCancel = (): void => {
    setEdit(false)
  }

  return (
    <div>
      <div className="mb-5">
        <div className="mx-5">
          <SubTabHeader
            title="Tags"
            buttonText={edit ? undefined : 'Edit'}
            onClick={handleEdit}
          />
        </div>
        <Card>
          {!edit &&
            (tagNames.length > 0 ? (
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {tagNames.map((tag, index) => (
                  <Chip key={index} label={tag} variant="outlined" />
                ))}
              </Stack>
            ) : (
              <Typography>None</Typography>
            ))}
          {edit && (
            <div>
              <TagInput
                entity="product"
                onChange={handleTagsChange}
                defaultValues={tagIds}
              />
              <div className="mt-4">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ textTransform: 'none', mr: 2 }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ textTransform: 'none' }}
                  onClick={handleSave}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Saving' : 'Save'}
                </Button>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}

export default TagsDetails
