import { FC, useState } from 'react'

import { Grid, Select, MenuItem, Button, Typography } from '@mui/material'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import { SubTabHeader } from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'
import { Product } from 'types/product'

interface ManageStatusDetailProps {
  product: Product
  onUpdate: (product: Product) => void
}

const ManageStatusDetail: FC<ManageStatusDetailProps> = ({
  product,
  onUpdate,
}) => {
  const [isActive, setIsActive] = useState<boolean>(product.isActive)
  const [isListed, setIsListed] = useState<boolean>(product.isListed)
  const [edit, setEdit] = useState<boolean>(false)
  const { organizationId } = useAuth()
  const { showSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const handleEdit = (): void => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_MANAGE_STATUS_EDIT_CLICKED', {
        productId: product.id,
        orgId: organizationId,
      })
    }
    setEdit(true)
  }

  const handleCancel = (): void => {
    setEdit(false)
    setIsListed(product.isListed)
    setIsActive(product.isActive)
  }

  const handleSave = async (): Promise<void> => {
    if (organizationId) {
      Amplitude.trackEvent('PRODUCT_MANAGE_STATUS_SAVE_CLICKED', {
        productId: product.id,
        orgId: organizationId,
      })
    }

    if (isListed === product.isListed && isActive === product.isActive) {
      setEdit(false)
      return
    }
    setIsSaving(true)

    const result = await updateProduct({
      productId: product.id,
      dataUpdate: { isListed: isListed, isActive: isActive },
    })
    if (result.status === 'successful' && result.data) {
      onUpdate(result.data)
      showSnackbar('Product status updated.', 'success')
    } else {
      showSnackbar('Failed to update product status.', 'error')
    }
    setEdit(false)
    setIsSaving(false)
  }

  return (
    <div className="mb-5">
      <div className="mx-5">
        <SubTabHeader
          title="Manage Status"
          buttonText={edit ? undefined : 'Edit'}
          onClick={handleEdit}
        />
      </div>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Label text="Is Active" color="secondary" />
            {!edit && (
              <Typography>{product.isActive ? 'Yes' : 'No'}</Typography>
            )}
            {edit && (
              <Select
                value={isActive ? 'yes' : 'no'}
                onChange={(event) => setIsActive(event.target.value === 'yes')}
                fullWidth
                sx={{ fontSize: '0.8rem', padding: 0, height: '40px' }}
              >
                <MenuItem
                  sx={{
                    fontSize: '0.75rem',
                    '&:hover': {
                      backgroundColor: 'lightgray',
                    },
                  }}
                  value="yes"
                >
                  Yes
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: '0.75rem',
                    '&:hover': {
                      backgroundColor: 'lightgray',
                    },
                  }}
                  value="no"
                >
                  No
                </MenuItem>
              </Select>
            )}
          </Grid>
          <Grid item xs={6}>
            <Label text="Is Listed" color="secondary" />
            {!edit && (
              <Typography>{product.isListed ? 'Yes' : 'No'}</Typography>
            )}
            {edit && (
              <Select
                value={isListed ? 'yes' : 'no'}
                onChange={(event) => setIsListed(event.target.value === 'yes')}
                fullWidth
                sx={{ fontSize: '0.8rem', padding: 0, height: '40px' }}
              >
                <MenuItem
                  sx={{
                    fontSize: '0.75rem',
                    '&:hover': {
                      backgroundColor: 'lightgray',
                    },
                  }}
                  value="yes"
                >
                  Yes
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: '0.75rem',
                    '&:hover': {
                      backgroundColor: 'lightgray',
                    },
                  }}
                  value="no"
                >
                  No
                </MenuItem>
              </Select>
            )}
          </Grid>
        </Grid>
        {edit && (
          <div className="mt-4">
            <Button
              variant="outlined"
              size="small"
              sx={{ textTransform: 'none', mr: 2 }}
              onClick={handleCancel}
              disabled={isSaving}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </div>
        )}
      </Card>
    </div>
  )
}

export default ManageStatusDetail
