import { ChangeEvent, FC, useEffect, useState } from 'react'

import { Button, Chip, Grid, InputAdornment, Typography } from '@mui/material'

import Card from 'components/card'
import Label from 'components/forms/Label'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { updateProductOptionValue } from 'services/products'
import { ProductOption } from 'services/products/index.types'
import { Product } from 'types/product'

interface OptionPriceAndStockProps {
  variantOptions: ProductOption[]
  handleNextStep: () => void
  productCode: string
  onPrevStep: (step?: number) => void
  product: Product | null
  onUpdateOptions: (ops: ProductOption[]) => void
}

interface OptionValue {
  optionId: number
  valueId: number
  name: string
  value: string
  type: string
  price: string
  stock: string
  priceAdjustment: string
}

const OptionPriceAndStock: FC<OptionPriceAndStockProps> = ({
  productCode,
  variantOptions,
  handleNextStep,
  onPrevStep,
  onUpdateOptions,
}) => {
  const [productSecondaryOptions, setProductSecondaryOptions] = useState<
    OptionValue[]
  >([])
  const [primaryOptions, setPrimaryOptions] = useState<OptionValue[]>([])
  const { organizationId } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSnackbar } = useSnackbar()

  const handlePriceChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ): void => {
    const updatedOptions = [...primaryOptions]
    updatedOptions[index].price = e.target.value
    setPrimaryOptions(updatedOptions)
  }

  const handlePriceAdjustmentChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ): void => {
    const updatedOptions = [...productSecondaryOptions]
    updatedOptions[index].priceAdjustment = e.target.value
    setProductSecondaryOptions(updatedOptions)
  }

  const handlePrimaryStockChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ): void => {
    const updatedOptions = [...primaryOptions]
    updatedOptions[index].stock = e.target.value
    setPrimaryOptions(updatedOptions)
  }

  const handleSecondaryStockChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ): void => {
    const updatedOptions = [...productSecondaryOptions]
    updatedOptions[index].stock = e.target.value
    setProductSecondaryOptions(updatedOptions)
  }

  useEffect(() => {
    setPrimaryOptions([])
    setProductSecondaryOptions([])

    variantOptions.forEach((option) => {
      if (option.type === 'PRIMARY') {
        const newPrimaryOptions = option.ProductOptionValue.sort(
          (a, b) => a.id - b.id
        ).map((value) => ({
          optionId: option.id,
          valueId: value.id,
          name: option.name,
          value: value.value,
          type: 'PRIMARY',
          price: value.price ? (value.price / 100).toString() : '',
          stock: value.stock ? value.stock.toString() : '',
          priceAdjustment: '',
        }))
        setPrimaryOptions(newPrimaryOptions)
      } else {
        const newSecondaryOptions = option.ProductOptionValue.sort(
          (a, b) => a.id - b.id
        ).map((value) => ({
          optionId: option.id,
          valueId: value.id,
          name: option.name,
          value: value.value,
          type: 'SECONDARY',
          price: '',
          stock: value.stock ? value.stock.toString() : '',
          priceAdjustment: value.priceAdjustment
            ? (value.priceAdjustment / 100).toString()
            : '',
        }))
        setProductSecondaryOptions((prev) => [...prev, ...newSecondaryOptions])
      }
    })
  }, [variantOptions])

  const handleSubmit = async (): Promise<void> => {
    // Validate primary options
    if (primaryOptions.length > 0) {
      const hasEmptyPrice = primaryOptions.some((option) => option.price === '')
      if (hasEmptyPrice) {
        showSnackbar('Please enter the price', 'error')
        return
      }
    }

    setIsSubmitting(true)

    const data = primaryOptions.map((option) => ({
      optionId: option.optionId,
      valueId: option.valueId,
      price: Number(option.price) * 100,
      stock: option.stock !== '' ? Number(option.stock) : undefined,
    }))

    const secondaryData = productSecondaryOptions.map((option) => ({
      optionId: option.optionId,
      valueId: option.valueId,
      priceAdjustment: Number(option.priceAdjustment) * 100,
      stock: option.stock !== '' ? Number(option.stock) : undefined,
    }))

    const allData = [...data, ...secondaryData]

    const res = organizationId
      ? await updateProductOptionValue(organizationId, productCode, {
          optionValues: allData,
        })
      : { status: 'failed', message: 'Organization ID is required' }

    const updatedVariantOptions = variantOptions.map((op) => {
      return {
        ...op,
        ProductOptionValue: op.ProductOptionValue.map((vl) => {
          const updatedData = allData.find((opVal) => opVal.valueId === vl.id)

          if (updatedData) {
            if (op.type === 'PRIMARY' && 'price' in updatedData) {
              return {
                ...vl,
                price: updatedData.price,
                stock: updatedData.stock ?? null,
              }
            } else if ('priceAdjustment' in updatedData) {
              return {
                ...vl,
                priceAdjustment: updatedData.priceAdjustment,
                stock: updatedData.stock ?? null,
              }
            }
          }
          return vl
        }),
      }
    })

    if (res.status === 'successful') {
      handleNextStep()
      onUpdateOptions(updatedVariantOptions)
    } else {
      showSnackbar(
        res.message || 'Failed to update options. Please try again.',
        'error'
      )
    }

    setIsSubmitting(false)
  }

  return (
    <div>
      <div>
        <div className="flex items-center justify-between ml-5 mr-5">
          <h2 className="text-lg font-semibold">Set Options</h2>
          <Chip
            label="Primary Variant Option"
            sx={{
              color: '#15803d',
              backgroundColor: '#bbf7d0',
              fontSize: '12px',
            }}
          />
        </div>
        {primaryOptions.length === 0 && (
          <Card>
            <Typography>No primary option</Typography>
          </Card>
        )}
        {primaryOptions.map((option, index) => (
          <Card key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Label text={'Option'} color="secondary" />
                <Typography variant="body1" fontWeight={600}>
                  {option.value}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Label text={'Selling Price*'} color="secondary" />
                <CustomTextField
                  type="number"
                  step={0.01}
                  min={0}
                  name="price"
                  value={option.price}
                  onChange={(e) => handlePriceChange(e, index)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Label text={'Quantity in Stock'} color="secondary" />
                <CustomTextField
                  type="number"
                  step={1}
                  min={0}
                  name="stock"
                  value={option.stock}
                  onChange={(e) => handlePrimaryStockChange(e, index)}
                />
              </Grid>
            </Grid>
          </Card>
        ))}
      </div>
      {productSecondaryOptions.length > 0 && (
        <div>
          <div className="flex items-center justify-between ml-5">
            <h2 className="text-lg font-semibold">SECONDARY OPTIONS</h2>
          </div>
          {productSecondaryOptions.map((option, index) => (
            <Card key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Label text={'Option'} color="secondary" />
                  <Typography variant="body1" fontWeight={600}>
                    {option.value}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Label text={'Additional Price'} color="secondary" />
                  <CustomTextField
                    type="number"
                    step={0.01}
                    min={0}
                    name="priceAdjustment"
                    value={option.priceAdjustment}
                    onChange={(e) => handlePriceAdjustmentChange(e, index)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Label text={'Quantity in Stock'} color="secondary" />
                  <CustomTextField
                    type="number"
                    step={1}
                    min={0}
                    name="stock"
                    value={option.stock}
                    onChange={(e) => handleSecondaryStockChange(e, index)}
                  />
                </Grid>
              </Grid>
            </Card>
          ))}
        </div>
      )}
      <div className="mx-5">
        <div className="flex items-center w-full">
          <div className="flex-1 basis-1/2">
            <Button
              size="small"
              variant="outlined"
              sx={{
                textTransform: 'none',
              }}
              onClick={() => onPrevStep(undefined)}
            >
              Prev: Product Variants
            </Button>
          </div>
          <div className="flex-1 basis-3/5">
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ textTransform: 'none' }}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Next: Add Notes & Tags'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OptionPriceAndStock
