import React, { useEffect, useState } from 'react'

import { Box, Button, Grid, InputAdornment, TextField } from '@mui/material'

import Card from 'components/card'
import Label from 'components/forms/Label'
import QuillEditor from 'components/quill'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import CategoryInput from 'pages/dashboard/components/CategoryInput'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import { createProduct, updateProduct } from 'services/products'
import { Product } from 'types/product'

interface ProductDetailsProps {
  onProductCreate: (id: number, code: string) => void
  onCreate: (product: Product) => void
  product: Product | null
  onNextStep: () => void
}

export interface BasicDetails {
  name: string
  category: number[]
  description: string
  price: string
  quantity: string
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  onProductCreate,
  onCreate,
  product,
  onNextStep,
}) => {
  const [basicDetails, setBasicDetails] = useState<BasicDetails>({
    name: '',
    category: [],
    description: '',
    price: '',
    quantity: '',
  })
  const [errors, setErrors] = useState({
    name: '',
    price: '',
    quantity: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { organizationId } = useAuth()

  useEffect(() => {
    if (product) {
      setBasicDetails({
        name: product.name,
        description: product.description,
        category: product.categoryIds,
        price: (product.price / 100).toString(),
        quantity: product.stock.toString(),
      })
    }
  }, [product])

  const handleDescriptionChange = (content: string): void => {
    setBasicDetails((prev) => ({
      ...prev,
      description: content,
    }))
  }

  const handleCategoryChange = (ids: number[]): void => {
    setBasicDetails((prev) => ({
      ...prev,
      category: ids,
    }))
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setBasicDetails((prev) => ({
      ...prev,
      name: e.target.value,
    }))
  }

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setBasicDetails((prev) => ({
      ...prev,
      price: e.target.value,
    }))
  }

  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setBasicDetails((prev) => ({
      ...prev,
      quantity: e.target.value,
    }))
  }

  const handleNext = async (): Promise<void> => {
    if (
      basicDetails.name === '' ||
      basicDetails.price === '' ||
      basicDetails.quantity === ''
    ) {
      setErrors((prev) => ({
        ...prev,
        name: basicDetails.name === '' ? '*Name is required' : ' ',
        price: basicDetails.price === '' ? '*Price is required' : ' ',
        quantity: basicDetails.quantity === '' ? '*Quantity is required' : ' ',
      }))
      return
    }

    setIsSubmitting(true)

    const values = {
      name: basicDetails.name,
      description: basicDetails.description,
      media: [],
      price: Number(basicDetails.price) * 100,
      quantity:
        basicDetails.quantity !== '' ? Number(basicDetails.quantity) : 0,
      categoryIds: basicDetails.category,
    }

    if (!product) {
      const result = organizationId
        ? await createProduct({
            ...values,
            organizationId: organizationId,
            isActive: false,
            isListed: false,
          })
        : { status: 'failed', message: 'Organization not found' }

      if (result.status === 'successful' && result.data) {
        onProductCreate(result.data.id, result.data.productCode)
        onCreate(result.data)
      } else if (result.status === 'failed') {
        showSnackbar(result.message || 'Something went wrong', 'error')
      }
    } else if (product) {
      if (
        basicDetails.name.trim() === product.name.trim() &&
        values.price === product.price &&
        values.description.trim() === product.description.trim() &&
        values.quantity === product.stock &&
        values.categoryIds.length === product.categoryIds.length &&
        values.categoryIds.every(
          (value, index) => value === product.categoryIds[index]
        )
      ) {
        setIsSubmitting(false)
        setErrors({
          name: '',
          price: '',
          quantity: '',
        })
        onNextStep()
        return
      }

      const result: { status: string; data?: Product; message?: string } =
        organizationId
          ? await updateProduct({
              productId: product.id,
              dataUpdate: values,
            })
          : { status: 'failed', message: 'Organization not found' }

      if (result.status === 'successful' && result.data) {
        onProductCreate(result.data.id, result.data.productCode)
        onCreate(result.data)
      } else if (result.status === 'failed') {
        showSnackbar(result.message || 'Something went wrong', 'error')
      }
    }

    setIsSubmitting(false)

    setErrors({
      name: '',
      price: '',
      quantity: '',
    })
  }

  return (
    <div className="mb-5">
      <h2 className="text-lg font-semibold ml-5">Details</h2>

      <Card>
        <Box>
          <Label text="Name" htmlFor="name" />
          <TextField
            sx={{ marginTop: 0 }}
            fullWidth
            inputProps={{ style: { backgroundColor: 'white' } }}
            id="name"
            name="name"
            value={basicDetails.name}
            onChange={handleNameChange}
            variant="outlined"
            size="small"
            error={errors.name !== ''}
            helperText={errors.name}
          />
        </Box>
        <Box className="mt-4">
          <Label text="Category" />
          <CategoryInput
            onChange={handleCategoryChange}
            defaultValues={product ? product.categoryIds : undefined}
          />
        </Box>
        <Box className="mt-4">
          <Label text="Description" htmlFor="description" />
          <Box className="flex">
            <Box className="flex-1">
              <QuillEditor
                value={basicDetails.description}
                onChange={handleDescriptionChange}
                placeholder="Write your product description here..."
              />
            </Box>
          </Box>
        </Box>
      </Card>
      <h2 className="text-lg font-semibold ml-5">Pricing & Stock</h2>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Label text="Selling Price" htmlFor="price" />
            <CustomTextField
              type="number"
              name="price"
              min={0}
              step={0.01}
              value={basicDetails.price}
              onChange={handlePriceChange}
              error={errors.price !== ''}
              helperText={errors.price}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text="Quantity In Stock" htmlFor="quantity" />
            <CustomTextField
              type="number"
              name="quantity"
              min={0}
              value={basicDetails.quantity}
              onChange={handleQuantityChange}
              error={errors.quantity !== ''}
              helperText={errors.quantity}
            />
          </Grid>
        </Grid>
      </Card>
      <Box className="mt-4 flex justify-center items-center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          size="small"
          sx={{
            textTransform: 'none',
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Next: Add Images'}
        </Button>
      </Box>
    </div>
  )
}

export default ProductDetails
