import { FC, ChangeEvent, useState, useEffect } from 'react'

import { Box, Typography, Dialog } from '@mui/material'
import { useParams } from 'react-router-dom'

import Card from 'components/card'
import SingleImageCropper from 'components/files/SingleFileCropDialog'
import Label from 'components/forms/Label'
import DisplayImage from 'pages/dashboard/components/DisplayImage'
import InputFileUpload from 'pages/dashboard/components/InputFileUpload'
import { getPresignedUrl, uploadFile, deleteFile } from 'services/files'
import { updateExistingCategory } from 'services/products/categories'
import { validateCategoryImage } from 'utils/validation'

interface DetailsSectionProps {
  isEditing: boolean
  description: string
  imageUrl: string | null
  setImageUrl: (url: string) => void
  setDescription: (desc: string) => void
  setIsEditing?: (isEditing: boolean) => void
}

const fileUrl = process.env.REACT_APP_FILE_URL

const CategoryDetailsSection: FC<DetailsSectionProps> = ({
  isEditing,
  description,
  imageUrl,
  setImageUrl,
  setDescription,
}) => {
  const [image, setImage] = useState<string | null>(imageUrl)
  const [error, setError] = useState<{ type: string; message: string } | null>(
    null
  )
  const [uploadProgress, setUploadProgress] = useState<{
    progress: number
    fileName: string
  }>({
    progress: 0,
    fileName: '',
  })
  const [isUploading, setIsUploading] = useState(false)
  const { categoryId } = useParams<{ categoryId: string }>()
  const [cropFile, setCropFile] = useState<File | null>(null)
  const [showCropper, setShowCropper] = useState(false)

  useEffect(() => {
    setImage(imageUrl)
  }, [imageUrl])

  const handleSave = async (file: File): Promise<void> => {
    if (!categoryId) {
      setError({
        type: 'image',
        message: 'Category ID is missing. Cannot update category.',
      })
      return
    }

    setIsUploading(true)

    try {
      const { url: signedUrl, fileName: uuid } = await getPresignedUrl()
      setUploadProgress({ progress: 0, fileName: file.name })

      const result = await uploadFile(file, signedUrl, (progress) => {
        setUploadProgress({ progress, fileName: file.name })
      })

      if (result.status === 'successful') {
        const imageUrl = `${fileUrl}/${uuid}`
        setImage(imageUrl)
        setImageUrl(imageUrl)
        setError(null)

        const updateData = { imageUrl }
        const updateResult = await updateExistingCategory(
          categoryId,
          updateData
        )

        if (updateResult.status !== 'successful') {
          setError({
            type: 'update',
            message: updateResult.message || 'Failed to update category.',
          })
        }
      } else {
        setError({
          type: 'upload',
          message: 'File upload failed.',
        })
      }
    } catch (error) {
      setError({
        type: 'upload',
        message: 'An error occurred while uploading the file.',
      })
    } finally {
      setIsUploading(false)
    }
  }

  const handleFileChange = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files) return

    const file = e.target.files[0]
    const validate = await validateCategoryImage(file)

    if (!validate.valid)
      return setError({
        type: 'image',
        message: validate.error || 'An error occurred.',
      })

    setCropFile(file)
    setShowCropper(true)
  }

  const handleCropComplete = (croppedFile: File): void => {
    handleSave(croppedFile)
    setCropFile(null)
    setShowCropper(false)
  }

  const handleCropCancel = (): void => {
    setShowCropper(false)
    setCropFile(null)
  }

  const handleDeleteImage = async (): Promise<void> => {
    if (!image) return

    if (!categoryId) {
      setError({
        type: 'image',
        message: 'Category ID is missing. Cannot delete image.',
      })
      return
    }

    const fileName = image.split('/')[3]

    try {
      const result = await deleteFile(fileName)

      if (result.status === 'successful') {
        setImage(null)
        setImageUrl('')

        const updateData = { imageUrl: '' }
        const updateResult = await updateExistingCategory(
          categoryId,
          updateData
        )

        if (updateResult.status !== 'successful') {
          setError({
            type: 'update',
            message:
              updateResult.message ||
              'Failed to update category after image deletion.',
          })
        }
      } else {
        setError({
          type: 'delete',
          message: 'Image deletion failed.',
        })
      }
    } catch (error) {
      setError({
        type: 'delete',
        message: 'An error occurred while deleting the image.',
      })
    }
  }

  return (
    <>
      <Box
        className="mx-5 mb-4"
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography sx={{ fontWeight: '700' }}>Details</Typography>
      </Box>
      <Card>
        <Box className="mb-4">
          <Label text="Description" color="secondary" />
          {isEditing ? (
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full border border-gray-300 rounded py-2 px-3 focus:border-black focus:outline-none"
            />
          ) : (
            <Typography variant="body1">{description || 'N/A'}</Typography>
          )}
        </Box>
        <Label text="Image" color="secondary" />
        {image ? (
          <Box>
            <DisplayImage
              imageUrl={image}
              width={100}
              height={150}
              onDelete={isEditing ? handleDeleteImage : undefined}
            />
          </Box>
        ) : isEditing ? (
          <InputFileUpload
            name="image"
            onChange={handleFileChange}
            buttonText={isUploading ? 'Uploading' : 'Upload File'}
          />
        ) : (
          <Typography variant="body1">N/A</Typography>
        )}
        {uploadProgress.progress > 0 && uploadProgress.progress < 100 && (
          <Typography sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
            {uploadProgress.fileName} - {uploadProgress.progress}%
          </Typography>
        )}
        {error && error.type === 'image' && (
          <p className="text-xs text-red-500 block mt-1">{error.message}</p>
        )}
      </Card>
      {showCropper && cropFile && (
        <Dialog open={showCropper} maxWidth="md" fullWidth>
          <SingleImageCropper
            file={cropFile}
            onCropComplete={handleCropComplete}
            onClose={handleCropCancel}
          />
        </Dialog>
      )}
    </>
  )
}

export default CategoryDetailsSection
