import { Button, Paper } from '@mui/material'

import Card from 'components/card'

interface ProductVariantsProps {
  productId?: number
  handleNoVariantsStep?: () => void
  onNextStep: () => void
  onPrevStep: (step?: number) => void
}

const ProductVariants: React.FC<ProductVariantsProps> = ({
  handleNoVariantsStep,
  onNextStep,
  onPrevStep,
}) => {
  return (
    <div>
      <h2 className="text-lg font-semibold ml-5">Product Variants</h2>
      <Card>
        <Paper
          elevation={0}
          className="py-6 px-10 flex flex-col items-center justify-center"
        >
          <p className="text-sm text-center mb-4 font-semibold">
            Create size, color, or any other variants to offer multiple options
            for your product. For example. a T-shirt can have variants like Red,
            Blue, or Black in sizes Small, Medium, and Large.
          </p>
          <Button
            variant="contained"
            size="small"
            sx={{ textTransform: 'none' }}
            onClick={onNextStep}
          >
            Add Variants
          </Button>
        </Paper>
        <div className="flex justify-end mt-4">
          <Button
            variant="text"
            size="small"
            sx={{ textTransform: 'none', textDecoration: 'underline' }}
            onClick={handleNoVariantsStep}
          >
            This Product no variants, skip to the next step &gt;&gt;
          </Button>
        </div>
      </Card>
      <div className="mx-5">
        <Button
          size="small"
          variant="outlined"
          sx={{
            textTransform: 'none',
          }}
          onClick={() => onPrevStep(undefined)}
        >
          Prev: Add Images
        </Button>
      </div>
    </div>
  )
}

export default ProductVariants
